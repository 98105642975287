import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RouterModule, TitleStrategy } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import 'hammerjs';
import { QuillModule } from 'ngx-quill';

import { RouterFacade } from '@core/shared/data-access';
import {
  AbstractThemeChangesTrackingService,
  GlobalErrorHandlerService,
  IconRegistryModule,
  ThemeChangesTrackingDevService,
} from '@core/shared/util';
import { AbstractLoginContextProviderService } from '@mp/kernel/auth/data-access';
import { AppInsightsFeatureModule } from '@mp/shared/app-insights/feature';
import { HttpErrorInterceptor, PlatformRouterSerializer, SharedModule } from '@mp/shared/data-access';
import { AbstractAuthInfoService } from '@mp/shared/util';
import { ActiveOrganizationInterceptor, provideProfileDataAccess } from '@mpauth/shared/data-access';
import { organizationDetailsTabPlugin, userDetailsTabPlugin } from '@mpbi/shared/feature';
import {
  CURRENT_ORGANIZATION_USER_DETAILS_TAB_PLUGIN,
  ORGANIZATION_DETAILS_TAB_PLUGIN,
  USER_DETAILS_TAB_PLUGIN,
} from '@mpk/shared/util';
import { currentOrganizationUserDetailsTabPlugin } from '@mpomd/shared/feature';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { REDUCERS_TOKEN, reducerProvider } from './app.reducer';
import { routes } from './app.routes';
import { AuthInfoService, LoginContextProviderService } from './auth';
import { CUSTOM_DATE_FORMATS, CustomDatePickerAdapter } from './date-adapter';
import { AppTitleStrategy } from './info/app-title-strategy';
import { appInitProvider } from './init/app-init.service';
import { MatPaginatorIntlGerman } from './locales/mat-paginator-intl-german';

registerLocaleData(localeDe, 'de', localeDeExtra);

@NgModule({
  imports: [
    SharedModule,
    AppInsightsFeatureModule,
    BrowserModule,
    MatNativeDateModule,
    RouterModule.forRoot(routes),
    StoreRouterConnectingModule.forRoot({ serializer: PlatformRouterSerializer }),
    StoreModule.forRoot(REDUCERS_TOKEN, {
      metaReducers: !environment.production ? [] : [],
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    EffectsModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    MatSnackBarModule,
    HttpClientModule,
    IconRegistryModule,
    QuillModule.forRoot(),
  ],
  providers: [
    provideProfileDataAccess(),
    appInitProvider,
    reducerProvider,
    provideAnimationsAsync(),
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: DateAdapter, useClass: CustomDatePickerAdapter },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ActiveOrganizationInterceptor,
      multi: true,
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlGerman },
    { provide: TitleStrategy, useClass: AppTitleStrategy },
    { provide: AbstractLoginContextProviderService, useClass: LoginContextProviderService },
    { provide: AbstractAuthInfoService, useClass: AuthInfoService },
    RouterFacade,

    !environment.production
      ? { provide: AbstractThemeChangesTrackingService, useClass: ThemeChangesTrackingDevService }
      : [],
    {
      provide: CURRENT_ORGANIZATION_USER_DETAILS_TAB_PLUGIN,
      multi: true,
      useValue: currentOrganizationUserDetailsTabPlugin,
    },
    { provide: ORGANIZATION_DETAILS_TAB_PLUGIN, multi: true, useValue: organizationDetailsTabPlugin },
    { provide: USER_DETAILS_TAB_PLUGIN, multi: true, useValue: userDetailsTabPlugin },
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { NgModule } from '@angular/core';
import { MedicalShopWidgetComponent } from './components';
import { MedicalShopDashboardService } from './services';

@NgModule({
  imports: [MedicalShopWidgetComponent],
  exports: [MedicalShopWidgetComponent],
  providers: [MedicalShopDashboardService],
})
export class MedicalShopDashboardModule {}

import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  concatMap,
  defaultIfEmpty,
  forkJoin,
  map,
  switchMap,
  tap,
  throwError,
} from 'rxjs';

import { NotificationService } from '@core/shared/util';
import { HelpCenterArticle, SupportTicket, SupportTicketRequest } from '@mp/shared/zendesk/domain';

import { SupportTicketCreationData } from '../models';
import { ZendeskService } from '../services';

import { ZENDESK_TICKET_CUSTOM_FIELDS } from './zendesk-ticket-custom-fields';

export interface ZendeskState {
  helpCenterArticles: HelpCenterArticle[] | undefined;
  articlesCount: number;
}

export const initialState: ZendeskState = {
  helpCenterArticles: undefined,
  articlesCount: 0,
};

@Injectable()
export class ZendeskStore extends ComponentStore<ZendeskState> {
  private readonly _ticketCreationSuccess$: Subject<void> = new Subject();
  readonly ticketCreationSuccess$: Observable<void> = this._ticketCreationSuccess$.asObservable();

  private readonly _ticketCreationError$: Subject<void> = new Subject();
  readonly ticketCreationError$: Observable<void> = this._ticketCreationError$.asObservable();

  readonly helpCenterArticles$: Observable<HelpCenterArticle[] | undefined> = this.select(
    (state) => state.helpCenterArticles,
  );

  readonly helpCenterArticlesCount$: Observable<number> = this.select((state) => state.articlesCount);

  constructor(
    private readonly zendeskService: ZendeskService,
    private readonly notificationService: NotificationService,
  ) {
    super(initialState);
  }

  readonly createTicket = this.effect(
    (supportTicketCreationData$: Observable<SupportTicketCreationData>): Observable<unknown> => {
      return supportTicketCreationData$.pipe(
        switchMap((supportTicketCreationData) =>
          this.uploadAttachments(supportTicketCreationData.attachments).pipe(
            map((attachmentsTokens) => this.createTicketCreationRequest(supportTicketCreationData, attachmentsTokens)),
            concatMap((supportTicketRequest) => this.handleCreateTicket(supportTicketRequest)),
            tap(() => {
              this._ticketCreationSuccess$.next();
              this.notificationService.toastSuccess('Ticket erfolgreich angelegt.');
            }),
            catchError(() => {
              this._ticketCreationError$.next();
              return EMPTY;
            }),
          ),
        ),
      );
    },
  );

  readonly searchArticles = this.effect((labelName$: Observable<string>): Observable<unknown> => {
    return labelName$.pipe(
      tap(() => this.setZendeskArticles(undefined)),
      switchMap((labelName) =>
        this.zendeskService.searchArticles(labelName).pipe(
          map(({ helpCenterArticles, articlesCount }) => {
            this.setZendeskArticles(helpCenterArticles);
            this.setZendeskArticlesCount(articlesCount);
          }),
          catchError(() => {
            this.setZendeskArticles([]);
            this.setZendeskArticlesCount(0);
            this.notificationService.toastDanger('Beim Laden der ZenDesk-Artikel ist ein Fehler aufgetreten.');
            return EMPTY;
          }),
        ),
      ),
    );
  });

  private readonly setZendeskArticles = this.updater(
    (state: ZendeskState, helpCenterArticles: HelpCenterArticle[] | undefined): ZendeskState => ({
      ...state,
      helpCenterArticles,
    }),
  );

  private readonly setZendeskArticlesCount = this.updater(
    (state: ZendeskState, articlesCount: number): ZendeskState => ({
      ...state,
      articlesCount,
    }),
  );

  private uploadAttachments(attachments: File[]): Observable<string[]> {
    const attachmentsTokensObservables: Observable<string>[] = attachments.map((attachment) =>
      this.zendeskService.uploadAttachment(attachment).pipe(map(({ upload }) => upload.token)),
    );

    return forkJoin(attachmentsTokensObservables).pipe(
      defaultIfEmpty([]),
      catchError((error: unknown) => {
        this.notificationService.toastDanger('Beim Hochladen des Anhangs ist ein Fehler aufgetreten.');
        return throwError(() => error);
      }),
    );
  }

  private handleCreateTicket(ticketCreationRequest: SupportTicketRequest): Observable<unknown> {
    return this.zendeskService.createTicket(ticketCreationRequest).pipe(
      catchError((error: unknown) => {
        this.notificationService.toastDanger('Beim Anlegen des Tickets ist ein Fehler aufgetreten.');
        return throwError(() => error);
      }),
    );
  }

  private createTicketCreationRequest(
    { subject, priority, body }: SupportTicketCreationData,
    attachmentsTokens: string[],
  ): SupportTicketRequest {
    const ticketRequest: SupportTicket = {
      subject,
      priority,
      comment: { body, uploads: attachmentsTokens },
      custom_fields: ZENDESK_TICKET_CUSTOM_FIELDS,
    };

    return { request: ticketRequest };
  }
}

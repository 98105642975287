import { TicketCustomField } from '@mp/shared/zendesk/domain';

// NOTE: Custom fields are hardcoded for now, but in the future they should be fetched from the backend for each deployment
export const ZENDESK_TICKET_CUSTOM_FIELDS: TicketCustomField[] = [
  {
    id: 12298212152593,
    value: 'mediio_plattform',
  },
  {
    id: 12298805039505,
    value: 'feedback',
  },
  {
    id: 12310699207057,
    value: 'stufe_1',
  },
];

import { InjectionToken } from '@angular/core';
import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { ROUTER_FEATURE_KEY } from '@mp/shared/data-access';

export const appGlobalReducers: ActionReducerMap<any> = {
  [ROUTER_FEATURE_KEY]: routerReducer,
};

export const REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<any>>('App Reducers');
export const reducerProvider = {
  provide: REDUCERS_TOKEN,
  useValue: appGlobalReducers,
};

import { EnvironmentProviders, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FeedbackService } from '../services';
import { FeedbackEffects, FeedbackFacade, feedbackFeatureKey, feedbackReducer } from '../store';

export function provideFeedbackDataAccess(): EnvironmentProviders[] {
  return [
    importProvidersFrom(StoreModule.forFeature(feedbackFeatureKey, feedbackReducer)),
    importProvidersFrom(EffectsModule.forFeature(FeedbackEffects)),
    makeEnvironmentProviders([FeedbackService, FeedbackFacade]),
  ];
}

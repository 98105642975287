import { Injectable, Optional } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { EMPTY, Observable, catchError, of, switchMap, tap } from 'rxjs';

import { FlyoutManager, LocalStorageService, filterUndefined } from '@core/shared/util';
import { SelectionItem, SelectorFlyoutRef, SelectorFlyoutService, wrapInSelectionItems } from '@core/ui';
import { OrganisationBasic } from '@mp/shared/kernel/newsfeed/domain';

import { NewsOrganisationsSelectorViewModel } from './news-organisations-selector-view-model';

type SelectedOrganisationsEntityState = EntityState<OrganisationBasic>;

export const selectedOrganisationsEntityAdapter = createEntityAdapter<OrganisationBasic>({
  selectId: (x) => x.organizationId,
});

export interface NewsOrganisationsSelectorState {
  selectedOrganisations: SelectedOrganisationsEntityState;
  availableOrganisations: OrganisationBasic[];
}

export const INITIAL_STATE: NewsOrganisationsSelectorState = {
  selectedOrganisations: selectedOrganisationsEntityAdapter.getInitialState(),
  availableOrganisations: [],
};

@Injectable()
export class NewsOrganisationsSelectorStore extends ComponentStore<NewsOrganisationsSelectorState> {
  private readonly ACTIVE_ORGANISATION_KEY = 'activeOrganisationId';
  readonly activeOrganisationId: string | null = this.localStorageService.tryReadingString(
    this.ACTIVE_ORGANISATION_KEY,
  );

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly selectorFlyoutService: SelectorFlyoutService,
    @Optional() private readonly flyoutManager: FlyoutManager | null,
  ) {
    super(INITIAL_STATE);
  }

  readonly selectedOrganisations$: Observable<OrganisationBasic[]> = this.select((state) =>
    selectAllSelectedOrganisations(state.selectedOrganisations),
  );

  readonly availableOrganisations$: Observable<OrganisationBasic[]> = this.select(
    (state) => state.availableOrganisations,
  );

  readonly vm$: Observable<NewsOrganisationsSelectorViewModel> = this.select(
    this.selectedOrganisations$,
    this.availableOrganisations$,
    (selectedOrganisations, availableOrganisations) => ({
      selectedOrganisations,
      availableOrganisations,
      activeOrganisationId: this.activeOrganisationId,
    }),
  );

  readonly setSelectedOrganisations = this.updater(
    (state, selectedOrganisations: OrganisationBasic[]): NewsOrganisationsSelectorState => ({
      ...state,
      selectedOrganisations: selectedOrganisationsEntityAdapter.setAll(
        selectedOrganisations,
        state.selectedOrganisations,
      ),
    }),
  );

  readonly setAvailableOrganisations = this.updater(
    (state, availableOrganisations: OrganisationBasic[]): NewsOrganisationsSelectorState => ({
      ...state,
      availableOrganisations,
    }),
  );

  readonly removeSelectedOrganisation = this.updater(
    (state, selectedOrganisationId: string): NewsOrganisationsSelectorState => ({
      ...state,
      selectedOrganisations: selectedOrganisationsEntityAdapter.removeOne(
        selectedOrganisationId,
        state.selectedOrganisations,
      ),
    }),
  );

  readonly promptOrganisationSelectionFlyout = this.effect((trigger$: Observable<void>): Observable<unknown> => {
    return trigger$.pipe(
      switchMap(() =>
        this.showAddOrganisationFlyout().pipe(
          tap((selectedOrganisations: OrganisationBasic[]) => this.addSelectedOrganisations(selectedOrganisations)),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  private readonly addSelectedOrganisations = this.updater(
    (state, addedOrganisations: OrganisationBasic[]): NewsOrganisationsSelectorState => ({
      ...state,
      selectedOrganisations: selectedOrganisationsEntityAdapter.addMany(
        addedOrganisations,
        state.selectedOrganisations,
      ),
    }),
  );

  private showAddOrganisationFlyout(): Observable<OrganisationBasic[]> {
    if (this.flyoutManager?.flyoutCurrentlyOpened || this.selectorFlyoutService.isCurrentlyOpened()) {
      return EMPTY;
    }

    const flyoutRef: SelectorFlyoutRef<SelectionItem<OrganisationBasic>> =
      this.selectorFlyoutService.openMultipleSelection(
        'Organisationen auswählen',
        this.buildOrganisationSelectionItems(),
        {
          useVirtualScroll: true,
          showSelectAllButton: true,
        },
      );

    this.flyoutManager?.markFlyoutAsOpen();
    this.flyoutManager?.subscribeToAfterClosed(flyoutRef, () => this.flyoutManager?.markFlyoutAsClosed());

    return flyoutRef.afterClosed$.pipe(filterUndefined());
  }

  private buildOrganisationSelectionItems(): Observable<SelectionItem<OrganisationBasic>[]> {
    const selectedOrganisations: OrganisationBasic[] = selectAllSelectedOrganisations(this.get().selectedOrganisations);
    const availableOrganisations: OrganisationBasic[] = this.get().availableOrganisations;

    const alreadyAttachedOrganisations = (organisation: OrganisationBasic) =>
      selectedOrganisations.some(({ organizationId }) => organizationId === organisation.organizationId);

    return of(availableOrganisations).pipe(
      wrapInSelectionItems({ header: 'name' }, { exclude: alreadyAttachedOrganisations }),
    );
  }
}

export const { selectAll: selectAllSelectedOrganisations } = selectedOrganisationsEntityAdapter.getSelectors();

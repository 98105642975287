<mp-dashboard-widget
  [widgetTitle]="title()"
  [icon]="icon()"
  [showSearchField]="true"
  [featurePageLink]="featurePageLink()"
  (search)="handleSearch($event)"
  (featurePageNavigation)="onFeaturePageNavigation()"
  [enabledByPermission]="enabledByPermission()"
  [noPermissionText]="noPermissionText()"
>
  <div class="article-statistics">
    @for (statistic of data(); track $index) {
      <mpcm-count-up-stat
        class="article-statistics__statistic"
        [statValue]="statistic.count"
        [statLabel]="statistic.label"
      />
    }
  </div>
</mp-dashboard-widget>

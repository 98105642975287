import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';

import {
  CreateTicketButtonComponent,
  HelpCenterDirectLinkButtonComponent,
  ZendeskHelpCenterButtonComponent,
} from '@mp/shared/helper-links/help-center/ui';
import { ZendeskSupportDialogContainerComponent } from '@mp/shared/helper-links/zendesk-support-dialog/feature';
import {
  ZENDESK_HC_ACCESS_TOKEN_KEY,
  ZendeskOAuthScope,
  provideZendeskAuthDataAccess,
} from '@mp/shared/zendesk/auth-data-access';
import { provideZendeskDataAccess } from '@mp/shared/zendesk/data-access';
import { provideZendeskApiDetailsService } from '@mp/shared/zendesk/util';

import { HelpCenterButtonViewModel } from './help-center-button-view-model';
import { HelpCenterButtonStore } from './help-center-button.store';

@Component({
  selector: 'mp-help-center-button',
  standalone: true,
  templateUrl: './help-center-button.component.html',
  styleUrls: ['./help-center-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    LetDirective,

    HelpCenterDirectLinkButtonComponent,
    ZendeskHelpCenterButtonComponent,
    ZendeskSupportDialogContainerComponent,
    CreateTicketButtonComponent,
  ],
  providers: [
    provideZendeskApiDetailsService(),
    provideZendeskAuthDataAccess(ZENDESK_HC_ACCESS_TOKEN_KEY, ZendeskOAuthScope.ReadArticles),
    provideZendeskDataAccess(),
    HelpCenterButtonStore,
  ],
})
export class HelpCenterButtonComponent {
  @Input({ required: true }) isSmall!: boolean;

  @Input({ required: true }) isZendeskFeedbackActive!: boolean;

  @ViewChild('createTicketButtonTemplate') createTicketButtonTemplate!: TemplateRef<unknown>;

  readonly vm$: Observable<HelpCenterButtonViewModel> = this.store.vm$;

  constructor(private readonly store: HelpCenterButtonStore) {}

  onOpenZendeskHelpCenterInfoFlyoutClick(): void {
    this.store.openZendeskHelpCenterInfoFlyout({
      showCreateTicketButton: this.isZendeskFeedbackActive,
      createTicketButtonTemplate: this.createTicketButtonTemplate,
    });
  }
}

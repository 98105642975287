import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch, pessimisticUpdate } from '@ngrx/router-store/data-persistence';
import { map, tap } from 'rxjs';

import { NotificationService } from '@core/shared/util';

import { FeedbackService } from '../services';

import { FeedbackActions } from './feedback.actions';

@Injectable()
export class FeedbackEffects {
  fetchFeedbackSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FeedbackActions.fetchFeedbackSettings),
      fetch({
        run: () => {
          return this.service
            .getFeedbackSettings()
            .pipe(map((feedbackSettings) => FeedbackActions.fetchFeedbackSettingsSuccess({ feedbackSettings })));
        },
        onError: () => {
          this.notificationService.toastDanger('Beim Laden der Feedback Einstellungen ist ein Fehler aufgetreten.');
          return FeedbackActions.fetchFeedbackSettingsError();
        },
      }),
    );
  });

  updateFeedbackSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FeedbackActions.updateFeedbackSettings),
      pessimisticUpdate({
        run: ({ feedbackSettings }) => {
          return this.service.updateFeedbackSettings(feedbackSettings).pipe(
            tap(() => this.notificationService.toastSuccess('Die Feedback Einstellungen wurden aktualisiert.')),
            map((feedbackSettings) => FeedbackActions.updateFeedbackSettingsSuccess({ feedbackSettings })),
          );
        },
        onError: () => {
          this.notificationService.toastDanger(
            'Beim Aktualisieren der Feedback Einstellungen ist ein Fehler aufgetreten.',
          );
          return FeedbackActions.updateFeedbackSettingsError();
        },
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly service: FeedbackService,
    private readonly notificationService: NotificationService,
  ) {}
}

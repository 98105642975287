import { EnvironmentProviders, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HelpCenterService } from '../services';
import { HelpCenterEffects, HelpCenterFacade, helpCenterFeatureKey, helpCenterReducer } from '../store';

export function provideHelpCenterDataAccess(): EnvironmentProviders[] {
  return [
    importProvidersFrom(StoreModule.forFeature(helpCenterFeatureKey, helpCenterReducer)),
    importProvidersFrom(EffectsModule.forFeature([HelpCenterEffects])),
    makeEnvironmentProviders([HelpCenterService, HelpCenterFacade]),
  ];
}

import { Injectable, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ComponentStore } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { Observable, exhaustMap, filter, map, switchMap, tap } from 'rxjs';

import { ViewModelSelector, filterNull, filterUndefined, getFeatureNameFromAppTitle } from '@core/shared/util';
import { ComponentFlyoutService } from '@core/ui';
import { AppConfigService } from '@mp/shared/data-access';
import { HelpCenterFacade } from '@mp/shared/helper-links/help-center/data-access';
import {
  HelpCenterSettings,
  HelpCenterType,
  ZendeskHelpCenterSettings,
} from '@mp/shared/helper-links/help-center/domain';
import {
  ZendeskHelpCenterInfoFlyoutComponent,
  ZendeskHelpCenterInfoFlyoutIntegrationData,
} from '@mp/shared/helper-links/help-center/ui';
import { ZendeskAuthFacade } from '@mp/shared/zendesk/auth-data-access';
import { ZendeskFacade } from '@mp/shared/zendesk/data-access';
import { ZendeskApiDetailsService } from '@mp/shared/zendesk/util';

import { HelpCenterButtonViewModel } from './help-center-button-view-model';

interface ZendeskHelpCenterInfoFlyoutTriggerData {
  showCreateTicketButton: boolean;
  createTicketButtonTemplate: TemplateRef<unknown>;
}

@Injectable()
export class HelpCenterButtonStore extends ComponentStore<Record<string, never>> {
  readonly zendeskClientId$: Observable<string | undefined> = this.appConfigService.envConfig$.pipe(
    map(({ zendeskClientId }) => zendeskClientId),
  );

  readonly helpCenterSettings$: Observable<HelpCenterSettings> =
    this.helpCenterFacade.helpCenterSettings$.pipe(filterUndefined());

  readonly vm$: Observable<HelpCenterButtonViewModel> = this.select(
    {
      helpCenterSettings: this.helpCenterSettings$,
      isZendeskEnabled: this.helpCenterSettings$.pipe(map(({ activeType }) => activeType === HelpCenterType.Zendesk)),
    } satisfies ViewModelSelector<HelpCenterButtonViewModel>,
    { debounce: true },
  );

  constructor(
    private readonly zendeskAuthFacade: ZendeskAuthFacade,
    private readonly zendeskFacade: ZendeskFacade,
    private readonly helpCenterFacade: HelpCenterFacade,
    private readonly zendeskApiDetailsService: ZendeskApiDetailsService,
    private readonly appConfigService: AppConfigService,
    private readonly componentFlyoutService: ComponentFlyoutService,
    private readonly titleService: Title,
  ) {
    super();

    this.initSetZendeskUrlEffect();
    this.initSetZendeskAccessTokenEffect();
    this.initZendeskAuthTokenResetEffect();
  }

  readonly openZendeskHelpCenterInfoFlyout = this.effect(
    (triggerData$: Observable<ZendeskHelpCenterInfoFlyoutTriggerData>): Observable<unknown> => {
      return triggerData$.pipe(
        concatLatestFrom(() => this.zendeskClientId$),
        exhaustMap(([{ showCreateTicketButton, createTicketButtonTemplate }, zendeskClientId]) =>
          this.zendeskAuthFacade.authorizeInZendesk(zendeskClientId).pipe(
            filterNull(),
            tap(() => this.fetchHelpCenterArticles()),
            concatLatestFrom(() => this.helpCenterSettings$),
            switchMap(([, { directLink }]) =>
              this.showZendeskHelpCenterInfoFlyout(directLink, showCreateTicketButton, createTicketButtonTemplate),
            ),
          ),
        ),
      );
    },
  );

  private showZendeskHelpCenterInfoFlyout(
    helpCenterLink: string | null,
    showCreateTicketButton: boolean,
    createTicketButtonTemplate: TemplateRef<unknown>,
  ): Observable<unknown> {
    const payload: ZendeskHelpCenterInfoFlyoutIntegrationData = {
      helpCenterLink,
      helpCenterWithArticlesLabelLink: this.gethelpCenterWithArticlesLabelLink(helpCenterLink),
      zendeskHelpCenterArticles$: this.zendeskFacade.helpCenterArticles$,
      zendeskHelpCenterArticlesCount$: this.zendeskFacade.helpCenterArticlesCount$,

      showCreateTicketButton,
      createTicketButtonTemplate,
    };

    return this.componentFlyoutService.open(ZendeskHelpCenterInfoFlyoutComponent, { payload }).afterClosed$;
  }

  private fetchHelpCenterArticles(): void {
    const articlesLabel: string = this.getFeatureNameFromAppTitle();

    this.zendeskFacade.searchArticles(articlesLabel);
  }

  private gethelpCenterWithArticlesLabelLink(helpCenterLink: string | null): string | null {
    return helpCenterLink ? `${helpCenterLink}/search?query=${this.getFeatureNameFromAppTitle()}` : null;
  }

  private getFeatureNameFromAppTitle(): string {
    return getFeatureNameFromAppTitle(this.titleService.getTitle());
  }

  private initSetZendeskUrlEffect(): void {
    this.effect((helpCenterSettings$: Observable<HelpCenterSettings>) =>
      helpCenterSettings$.pipe(
        filter(
          (helpCenterSettings): helpCenterSettings is ZendeskHelpCenterSettings =>
            helpCenterSettings.activeType === HelpCenterType.Zendesk,
        ),
        tap(({ zendeskUrl }) => this.zendeskApiDetailsService.setZendeskApiUrl(zendeskUrl)),
      ),
    )(this.helpCenterSettings$);
  }

  private initSetZendeskAccessTokenEffect(): void {
    this.effect((accessToken$: Observable<string | null>) =>
      accessToken$.pipe(tap((token) => this.zendeskApiDetailsService.setZendeskApiToken(token || ''))),
    )(this.zendeskAuthFacade.zendeskAccessToken$);
  }

  private initZendeskAuthTokenResetEffect(): void {
    this.effect((trigger$: Observable<unknown>) => trigger$.pipe(tap(() => this.zendeskAuthFacade.resetAuthToken())))(
      this.helpCenterFacade.updateHelpCenterSettingsSuccess$,
    );
  }
}

<mp-news-item-edit
  *ngrxLet="vm$ as vm"
  [newsItem]="newsItem"
  [showOrganisationsSelector]="vm.showOrganisationsSelector"
  [selectedOrganisations]="vm.selectedOrganisations"
  [availableOrganisations]="vm.availableOrganisations"
  (newsDelete)="deleteNewsEntry()"
  (newsSave)="saveNewsEntry($event)"
  (editModeClose)="onEditModeClose()"
></mp-news-item-edit>

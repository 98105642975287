import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { Observable } from 'rxjs';

import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';
import { ArticleCountsBaseWidgetComponent } from '@mpcm/shared';
import { ArticleCounts } from '@mpdm/shared';

import { MedicalShopWidgetInsightsEvent } from '../../insights';

import { MedicalShopWidgetStore } from './medical-shop-widget.store';

@Component({
  selector: 'mpms-medical-shop-widget',
  templateUrl: './medical-shop-widget.component.html',
  styleUrls: ['./medical-shop-widget.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, AsyncPipe, ArticleCountsBaseWidgetComponent],
  providers: [MedicalShopWidgetStore],
})
export class MedicalShopWidgetComponent {
  @HostBinding('class') readonly class = 'mpms-medical-shop-widget';

  readonly articleCounts$: Observable<ArticleCounts | undefined> = this.store.articleCounts$;

  constructor(
    private readonly store: MedicalShopWidgetStore,
    private readonly insightsEventsTrackingService: InsightsEventsTrackingService,
  ) {
    this.store.fetchArticleCounts();
  }

  handleSearch(searchTerm: string): void {
    this.insightsEventsTrackingService.trackEvent(MedicalShopWidgetInsightsEvent.SEARCH);
    this.store.navigateToArticleSearch(searchTerm);
  }

  onFeaturePageNavigation(): void {
    this.insightsEventsTrackingService.trackEvent(MedicalShopWidgetInsightsEvent.FEATURE_QUICK_ACCESS);
  }
}

import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'mp-zendesk-help-center-flyout-actions',
  standalone: true,
  templateUrl: './zendesk-help-center-flyout-actions.component.html',
  styleUrls: ['./zendesk-help-center-flyout-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyButtonModule, MatIconModule],
})
export class ZendeskHelpCenterFlyoutActionsComponent {
  helpCenterLink: InputSignal<string | null> = input.required<string | null>();
}

<mp-upload-form
  *ngrxLet="vm$ as vm"
  [uploadingProgress]="vm.uploadProgress"
  [status]="vm.fileUploadingStatus"
  [disabled]="disabled"
  [allowMultipleUploads]="false"
  [acceptedFileTypes]="acceptedFileTypes"
  [maxFileBytes]="maxFileBytes"
  (filesChange)="onFilesChange($event)"
>
  <mpcm-file-upload-label
    [status]="vm.fileUploadingStatus"
    [acceptedFileTypes]="acceptedFileTypes"
    [errorMessage]="vm.errorMessage"
  ></mpcm-file-upload-label>
</mp-upload-form>

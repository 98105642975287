import { Provider } from '@angular/core';

import { ZENDESK_ACCESS_TOKEN_KEY, ZENDESK_OAUTH_SCOPES } from '../injection-tokens';
import { ZendeskAuthFacade, ZendeskAuthService } from '../services';

export function provideZendeskAuthDataAccess(zendeskAccessTokenKey: string, zendeskOauthScopes: string): Provider[] {
  return [
    { provide: ZENDESK_ACCESS_TOKEN_KEY, useValue: zendeskAccessTokenKey },
    { provide: ZENDESK_OAUTH_SCOPES, useValue: zendeskOauthScopes },
    ZendeskAuthService,
    ZendeskAuthFacade,
  ];
}

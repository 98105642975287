import { ChangeDetectionStrategy, Component, HostBinding, InputSignal, input } from '@angular/core';

import { AvatarComponent } from '@core/ui';
import { Profile } from '@mp/auth/profile/domain';
import { UserType } from '@mpk/shared/domain';
import { UserImagePipe } from '@mpk/shared/util';

@Component({
  selector: 'mpauth-profile-display',
  standalone: true,
  templateUrl: './profile-display.component.html',
  styleUrl: './profile-display.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AvatarComponent, UserImagePipe],
})
export class ProfileDisplayComponent {
  protected readonly UserType = UserType;

  @HostBinding('class')
  protected get className() {
    return `mpauth-profile-display mpauth-profile-display--${this.size()}`;
  }

  /**
   * The size of the component.
   */
  readonly size: InputSignal<'small' | 'large'> = input<'small' | 'large'>('large');

  /**
   * The profile to display.
   */
  readonly profile = input<Profile | null>();
}

/* eslint-disable @nx/enforce-module-boundaries */
import { Resources } from '@mp/shared/data-access';

import { NavigationStructure } from './navigation-item';

//Use old permissions for now
export const navigationStructure: NavigationStructure = [
  {
    label: 'Analytics',
    route: 'analytics',
    icon: 'insert_chart',
    permission: {
      resource: 'Analytics',
      action: 'Benutzen',
    },
    loadChildRoutes: async () => (await import('@mpbi/routes')).BUSINESS_INTELLIGENCE_ANALYTICS_ROUTES,
  },
  {
    label: 'Artikelsuche',
    route: 'artikel',
    icon: 'search',
    permission: {
      resource: 'ContentManagementArtikel',
      action: 'Lesen',
    },
    loadChildRoutes: () =>
      import('libs/mediio-plattform/content-manager/artikelsuche/feature/src/lib/artikelsuche.module').then(
        (mod) => mod.ArtikelsucheModule,
      ),
  },
  {
    label: 'Belege',
    icon: 'list_alt',
    route: 'orders',
    children: [
      {
        label: 'Anforderungen',
        route: 'document-management',
        icon: 'fact_check',
        permission: {
          // For the moment: Same permission as for medical shop
          resource: 'Artikel',
          action: 'Lesen',
        },
        loadChildRoutes: () =>
          import('libs/mediio-plattform/document-management/core/src/lib/document-management-core.module').then(
            (mod) => mod.DocumentManagementCoreModule,
          ),
      },
    ],
  },
  {
    label: 'Content Management',
    icon: 'document_scanner',
    route: 'content-management',
    children: [
      {
        label: 'Matchvorschläge',
        route: 'match-proposals',
        icon: 'link',
        permission: {
          resource: 'Matcher',
          action: 'Benutzen',
        },
        loadChildRoutes: () =>
          import('@mp/content-manager/article-matching/match-proposals/routes').then(
            (mod) => mod.MATCH_PROPOSALS_ROUTES,
          ),
      },
    ],
  },
  {
    label: 'Katalog Management',
    icon: 'menu_book',
    route: 'katalog-management',
    children: [
      {
        label: 'Klinikkataloge',
        route: 'klinikkataloge',
        icon: 'menu_book',
        permission: {
          resource: 'KlinikKataloge',
          action: 'Lesen',
        },
        loadChildRoutes: () =>
          import('libs/mediio-plattform/content-manager/klinikkataloge/feature/src/lib/klinikkataloge.module').then(
            (mod) => mod.KlinikkatalogeModule,
          ),
      },
      {
        label: 'Industriekataloge',
        route: 'kataloge',
        icon: 'menu_book',
        permission: {
          resource: 'IndustrieKataloge',
          action: 'Lesen',
        },
        loadChildRoutes: () =>
          import(
            'libs/mediio-plattform/content-manager/industriekataloge/feature/src/lib/industriekataloge.module'
          ).then((mod) => mod.IndustriekatalogeModule),
      },
      {
        label: 'Plattformkataloge',
        route: 'plattformkataloge',
        icon: 'menu_book',
        permission: {
          resource: 'PlattformKataloge',
          action: 'Lesen',
        },
        loadChildRoutes: () =>
          import(
            'libs/mediio-plattform/content-manager/plattformkataloge/feature/src/lib/plattformkataloge.module'
          ).then((mod) => mod.PlattformkatalogeModule),
      },
      {
        label: 'Sortimente',
        route: 'article-ranges',
        icon: 'inventory_2',
        permission: {
          resource: 'Sortimente',
          action: 'Schreiben',
        },
        loadChildRoutes: () =>
          import('libs/mediio-plattform/content-manager/core/src/lib/articles/ranges/article-ranges.module').then(
            (mod) => mod.ArticleRangesModule,
          ),
      },
    ],
  },
  {
    label: 'Lieferanten Management',
    icon: 'local_shipping',
    route: 'lieferanten-management',
    children: [
      {
        label: 'Kliniklieferanten',
        route: 'kliniklieferanten',
        icon: 'local_shipping',
        permission: {
          resource: 'Kliniklieferanten',
          action: 'Lesen',
        },
        loadChildRoutes: async () => (await import('@mpsm/routes')).SUPPLIER_MANAGEMENT_CLINIC_SUPPLIERS_ROUTES,
      },
      {
        label: 'Plattformlieferanten',
        route: 'lieferanten',
        icon: 'local_shipping',
        permission: {
          resource: 'Lieferanten',
          action: 'Lesen',
        },
        loadChildRoutes: async () => (await import('@mpsm/routes')).SUPPLIER_MANAGEMENT_PLATFORM_SUPPLIERS_ROUTES,
      },
    ],
  },
  {
    label: 'Vetragsmanagement',
    icon: 'history_edu',
    route: 'contract-management',
    children: [
      {
        label: 'Konditionen',
        route: 'conditions',
        icon: 'request_quote',
        permission: {
          resource: Resources.ContractManagementConditions,
          action: 'Lesen',
        },
        loadChildRoutes: async () => (await import('@mpctm/routes')).CONTRACT_MANAGEMENT_CONDITIONS_ROUTES,
      },
      {
        label: 'Verträge',
        route: 'contracts',
        icon: 'receipt_long',
        permission: {
          resource: Resources.ContractManagementContracts,
          action: 'Lesen',
        },
        loadChildRoutes: async () => (await import('@mpctm/routes')).CONTRACT_MANAGEMENT_CONTRACTS_ROUTES,
      },
    ],
  },
  {
    label: 'Medical Shop',
    route: 'medical-shop2',
    icon: 'store',
    permission: {
      resource: 'Artikel',
      action: 'Lesen',
    },
    loadChildRoutes: () =>
      import('libs/mediio-plattform/medical-shop-2/core/src/lib/medical-shop-core.module').then(
        (mod) => mod.MedicalShopCoreModule,
      ),
  },
  {
    label: 'Meine Organisation',
    icon: 'home_work',
    route: 'organisation',
    children: [
      {
        label: 'Adressen',
        route: 'adressen',
        icon: 'location_on',
        permission: {
          resource: 'Adressen',
          action: 'Lesen',
        },
        loadChildRoutes: async () => (await import('@mpomd/routes')).ORGANIZATION_MASTER_DATA_ADDRESSES_ROUTES,
      },
      {
        label: 'API Keys',
        route: 'api-keys',
        icon: 'key',
        permission: {
          resource: 'ExportApiKeys',
          action: 'Bearbeiten',
        },
        loadChildRoutes: async () => (await import('@mpk/routes')).KERNEL_API_KEYS_ROUTES,
      },
      {
        label: 'Benutzer',
        route: 'benutzer',
        icon: 'people',
        permission: {
          resource: 'Benutzer',
          action: 'Lesen',
        },
        loadChildRoutes: async () => (await import('@mpk/routes')).KERNEL_CURRENT_ORGANIZATION_USERS_ROUTES,
      },
      {
        label: 'Info',
        route: 'info',
        icon: 'info',
        loadChildRoutes: () =>
          import('libs/mediio-plattform/my-organization/info/feature/src/lib/organization-info.module').then(
            (mod) => mod.OrganizationInfoModule,
          ),
      },
      {
        label: 'Kostenstellen',
        route: 'kostenstellen',
        icon: 'account_balance_wallet',
        permission: {
          resource: 'Kostenstellen',
          action: 'Lesen',
        },
        loadChildRoutes: async () => (await import('@mpomd/routes')).ORGANIZATION_MASTER_DATA_COST_CENTERS_ROUTES,
      },
      {
        label: 'Rollen',
        route: 'rollen',
        icon: 'policy',
        permission: {
          resource: 'Rollen',
          action: 'Lesen',
        },
        loadChildRoutes: async () => (await import('@mpk/routes')).KERNEL_CURRENT_ORGANIZATION_ROLES_ROUTES,
      },
    ],
  },
  {
    label: 'System',
    icon: 'settings',
    route: 'system',
    children: [
      {
        label: 'Benutzer',
        route: 'benutzer',
        icon: 'people',
        permission: {
          resource: 'Systembereich',
          action: 'Benutzen',
        },
        loadChildRoutes: async () => (await import('@mpk/routes')).KERNEL_USERS_ROUTES,
      },
      {
        label: 'Rollen',
        route: 'rollen',
        icon: 'policy',
        permission: {
          resource: 'Systembereich',
          action: 'Benutzen',
        },
        loadChildRoutes: async () => (await import('@mpk/routes')).KERNEL_ROLES_ROUTES,
      },
      {
        label: 'Organisationen',
        route: 'organisationen',
        icon: 'account_balance',
        permission: {
          resource: 'Systembereich',
          action: 'Benutzen',
        },
        loadChildRoutes: async () => (await import('@mpk/routes')).KERNEL_ORGANIZATIONS_ROUTES,
      },
      {
        label: 'Deployment Einstellungen',
        route: 'deployment-settings',
        icon: 'support',
        permission: {
          resource: 'Systembereich',
          action: 'Benutzen',
        },
        loadChildRoutes: async () => (await import('@mp/system/deployment-settings/routes')).DEPLOYMENT_SETTINGS_ROUTES,
      },
      {
        label: 'Plattformeinheiten',
        route: 'platform-packaging-units',
        icon: 'straighten',
        permission: {
          resource: 'PlatformPackagingUnits',
          action: 'Lesen',
        },
        loadChildRoutes: () =>
          import(
            'libs/mediio-plattform/system/platform-packaging-units/feature/src/lib/platform-packaging-units.module'
          ).then((mod) => mod.PlatformPackagingUnitsModule),
      },
      {
        label: 'Info',
        route: 'info',
        icon: 'info',
        loadChildRoutes: async () => (await import('@mp/system/info/feature')).INFO_ROUTES,
      },
    ],
  },
];

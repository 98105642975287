import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

@Component({
  selector: 'mp-create-news-bar',
  standalone: true,
  templateUrl: './create-news-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatCardModule, MatIconModule],
})
export class CreateNewsBarComponent {
  @Output() readonly createNewsClick: EventEmitter<void> = new EventEmitter<void>();

  onCreateNewsClick(): void {
    this.createNewsClick.emit();
  }
}

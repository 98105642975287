import { Injectable } from '@angular/core';

import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';


@Injectable()
export class MatPaginatorIntlGerman extends MatPaginatorIntl {

  override itemsPerPageLabel = 'Elemente pro Seite';
  override nextPageLabel = 'Nächste Seite';
  override previousPageLabel = 'Vorherige Seite';
  override firstPageLabel = 'Erste Seite';
  override lastPageLabel = 'Letzte Seite';

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 von ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} von ${length}`;
  };

}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { NewsfeedFacade, NewsfeedService } from './services';
import { NewsfeedEffects, newsfeedFeatureKey, newsfeedReducer } from './store';

@NgModule({
  imports: [StoreModule.forFeature(newsfeedFeatureKey, newsfeedReducer), EffectsModule.forFeature([NewsfeedEffects])],
  providers: [NewsfeedService, NewsfeedFacade],
})
export class NewsfeedDataAccessModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { QueryParams } from '@core/shared/util';

import { PendingPurchaseRequisitionCounts, PurchaseRequisitionsHistoryCounts } from '../../models';

@Injectable()
export class RequisitionsMetricsService {
  private readonly basePathApi = '/api/document-management';

  constructor(private readonly http: HttpClient) {}

  fetchPendingPurchaseRequisitionCounts(): Observable<PendingPurchaseRequisitionCounts> {
    return this.http.get<PendingPurchaseRequisitionCounts>(
      `${this.basePathApi}/metrics/pending-purchase-requisition-counts`,
    );
  }

  fetchPurchaseRequisitionsHistoryCounts(numberOfDays: number): Observable<PurchaseRequisitionsHistoryCounts> {
    const params = QueryParams.build().param('numberOfDays', numberOfDays).toHttpParams();

    return this.http.get<PurchaseRequisitionsHistoryCounts>(
      `${this.basePathApi}/metrics/purchase-requisitions-history`,
      { params },
    );
  }
}

import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FilesChangeEvent, UploadFormStatus } from '@core/ui';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { RejectedFile, RejectReason } from 'ngx-dropzone/lib/ngx-dropzone.service';
import { filter, map, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { DatasourceUploadTriggerOptions, FileInfo } from '../../models';
import { DatasourceUploadFacade } from '../../services';
import { DatasourceUploadWidgetStore } from '../datasource-upload-widget/datasource-upload-widget.store';
import { FileInfoDialogComponent } from '../file-info-dialog/file-info-dialog.component';
import { FileUploadHandlerViewModel } from './file-upload-handler-view-model';

export interface FileUploadHandlerState {
  fileUploadingStatus: UploadFormStatus;
  uploadProgress: number | undefined;
  errorMessage: FileUploadErrorMessage | undefined;
}

export const INITIAL_STATE: FileUploadHandlerState = {
  fileUploadingStatus: UploadFormStatus.PreUpload,
  uploadProgress: undefined,
  errorMessage: undefined,
};

export enum FileUploadErrorMessage {
  Default = 'Etwas ist schiefgelaufen!',
  MultipleFiles = 'Es können nicht mehrere Dateien hochgeladen werden.',
  ForbiddenType = 'Datei wurde abgelehnt. Grund: Dieses Dateiformat ist nicht erlaubt. Zugelassen sind: .zip, .rar, .xlsx, .xslm, .xls, .csv, .xml.',
  SizeExceeded = 'Datei wurde abgelehnt. Grund: Die Datei ist zu groß. Die maximale Größe darf 500 MB nicht überschreiten.',
  Unknown = 'Datei wurde abgelehnt. Grund: Unbekannter Fehler.',
}

@Injectable()
export class FileUploadHandlerStore extends ComponentStore<FileUploadHandlerState> {
  constructor(
    private readonly datasourceUploadWidgetStore: DatasourceUploadWidgetStore,
    private readonly datasourceUploadFacade: DatasourceUploadFacade,
    private readonly dialog: MatDialog
  ) {
    super(INITIAL_STATE);
  }

  readonly fileUploadingStatus$: Observable<UploadFormStatus> = this.select(
    (state) => state.fileUploadingStatus
  );

  readonly uploadProgress$: Observable<number | undefined> = this.select(
    (state) => state.uploadProgress
  );

  readonly errorMessage$: Observable<FileUploadErrorMessage | undefined> = this.select(
    (state) => state.errorMessage
  );

  readonly vm$: Observable<FileUploadHandlerViewModel> = this.select(
    this.fileUploadingStatus$,
    this.uploadProgress$,
    this.errorMessage$,
    (fileUploadingStatus, uploadProgress, errorMessage) => ({
      fileUploadingStatus,
      uploadProgress,
      errorMessage,
    })
  );

  readonly handleFileUploadEvent = this.effect(
    (fileUploadEvent$: Observable<FilesChangeEvent>): Observable<unknown> => {
      return fileUploadEvent$.pipe(
        filter((event) => this.validateFilesForUpload(event)),
        map(({ addedFiles }) => addedFiles[0]),
        switchMap((file) =>
          this.showFileInfoDialog().pipe(map((fileInfo) => ({ file, ...fileInfo })))
        ),
        tap(() => this.initFileUpload()),
        switchMap(({ file, fileType, comment }) =>
          this.datasourceUploadFacade
            .uploadFile({ file, fileType, comment }, (uploadProgress) =>
              this.setFileUploadProgress(uploadProgress)
            )
            .pipe(
              tapResponse(
                () => {
                  this.fileUploadComplete();
                  this.datasourceUploadWidgetStore.onFileUploadComplete();
                },
                () => this.fileUploadError(FileUploadErrorMessage.Default)
              )
            )
        )
      );
    }
  );

  readonly handleAttachmentFileUploadEvent = this.effect(
    (
      attachmentFileUploadEvent$: Observable<FilesChangeEvent<DatasourceUploadTriggerOptions>>
    ): Observable<unknown> => {
      return attachmentFileUploadEvent$.pipe(
        filter((event) => this.validateFilesForUpload(event)),
        map(({ addedFiles, uploadTriggerOptions }) => ({
          file: addedFiles[0],
          parentFileId: uploadTriggerOptions?.fileId as string,
        })),
        tap(() => this.initFileUpload()),
        switchMap(({ file, parentFileId }) =>
          this.datasourceUploadFacade
            .uploadFileAttachment(parentFileId, file, (uploadProgress) =>
              this.setFileUploadProgress(uploadProgress)
            )
            .pipe(
              tapResponse(
                (entry) => {
                  this.fileUploadComplete();
                  this.datasourceUploadWidgetStore.onAttachmentFileUploadComplete(
                    parentFileId,
                    entry
                  );
                },
                () => this.fileUploadError(FileUploadErrorMessage.Default)
              )
            )
        )
      );
    }
  );

  private readonly initFileUpload = this.updater(
    (state): FileUploadHandlerState => ({
      ...state,
      fileUploadingStatus: UploadFormStatus.Uploading,
      uploadProgress: undefined,
      errorMessage: undefined,
    })
  );

  private readonly fileUploadError = this.updater(
    (state, errorMessage: FileUploadErrorMessage): FileUploadHandlerState => ({
      ...state,
      fileUploadingStatus: UploadFormStatus.Error,
      uploadProgress: undefined,
      errorMessage: errorMessage,
    })
  );

  private readonly fileUploadComplete = this.updater(
    (state): FileUploadHandlerState => ({
      ...state,
      fileUploadingStatus: UploadFormStatus.Success,
      uploadProgress: undefined,
    })
  );

  private readonly setFileUploadProgress = this.updater(
    (state, fileUploadProgress: number): FileUploadHandlerState => ({
      ...state,
      uploadProgress: fileUploadProgress,
    })
  );

  private showFileInfoDialog(): Observable<FileInfo> {
    return this.dialog.open(FileInfoDialogComponent).afterClosed().pipe(filter(Boolean));
  }

  private validateFilesForUpload<T>({ addedFiles, rejectedFiles }: FilesChangeEvent<T>): boolean {
    if (rejectedFiles.length > 1 || (rejectedFiles.length && addedFiles.length)) {
      this.fileUploadError(FileUploadErrorMessage.MultipleFiles);
      return false;
    }
    if (rejectedFiles.length) {
      const rejectedFile: RejectedFile = rejectedFiles[0];
      const errorMessage: FileUploadErrorMessage = this.mapErrorReasonToMessage(
        rejectedFile.reason
      );
      this.fileUploadError(errorMessage);
      return false;
    }
    return true;
  }

  private mapErrorReasonToMessage(reason: RejectReason | undefined): FileUploadErrorMessage {
    switch (reason) {
      case 'type':
        return FileUploadErrorMessage.ForbiddenType;
      case 'size':
        return FileUploadErrorMessage.SizeExceeded;
      default:
        return FileUploadErrorMessage.Unknown;
    }
  }
}

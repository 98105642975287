<ng-container *ngrxLet="vm$ as vm">
  <div class="organisations-selector-header">
    <mat-icon
      class="organisations-selector-header__icon"
      [svgIcon]="vm.selectedOrganisations.length > 1 ? 'global_document_edit' : 'global_document_edit_disabled'"
    ></mat-icon>

    <span
      class="organisations-selector-header__text truncate"
      *ngrxLet="
        vm.selectedOrganisations.length > 1
          ? 'Als globale Nachricht verfassen'
          : 'Als organisationsbezogene Nachricht verfassen' as newsScopeText
      "
      [title]="newsScopeText"
    >
      {{ newsScopeText }}
    </span>
  </div>

  <mat-list class="organisations-selector-list">
    <mat-list-item class="organisations-selector-list__item" *ngFor="let organisation of vm.selectedOrganisations">
      <div class="organisation-item">
        <strong class="organisation-item__organisation-name">{{ organisation.name }}</strong>
        <button
          *ngIf="organisation.organizationId !== vm.activeOrganisationId"
          class="organisation-item__delete-icon"
          mat-icon-button
          (click)="removeOrganisationFromList(organisation.organizationId)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-list-item>
  </mat-list>

  <div class="organisations-selector-actions">
    <button
      *ngrxLet="vm.selectedOrganisations.length === vm.availableOrganisations.length as isAllOrganisationsSelected"
      class="organisations-selector-actions__add-action-button"
      mat-raised-button
      [disabled]="isAllOrganisationsSelected"
      matTooltip="Alle Organisationen wurden bereits ausgewählt."
      matTooltipPosition="above"
      [matTooltipDisabled]="!isAllOrganisationsSelected"
      (click)="promptOrganisationSelectionFlyout()"
    >
      <mat-icon>add</mat-icon>
      Organisation hinzüfugen
    </button>

    <span
      *ngrxLet="
        vm.selectedOrganisations.length
          | autoSingularOrPlural : 'Organisation' : 'Organisationen' as selectedOrganisationsInfo
      "
      class="organisations-selector-actions__action-info truncate"
      [title]="selectedOrganisationsInfo"
    >
      {{ selectedOrganisationsInfo }}
    </span>
  </div>
</ng-container>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FeedbackSettings } from '@mp/shared/helper-links/feedback/domain';

@Injectable()
export class FeedbackService {
  private readonly baseUrl = '/api/kernel/settings/deployment/feedback';

  constructor(private readonly http: HttpClient) {}

  /**
   * Fetches the feedback settings from the backend.
   * @returns The feedback settings.
   */
  getFeedbackSettings(): Observable<FeedbackSettings> {
    return this.http.get<FeedbackSettings>(this.baseUrl);
  }

  /**
   * Updates the feedback settings to the backend.
   * @param feedbackSettings The settings to save.
   * @returns The saved settings.
   */
  updateFeedbackSettings(feedbackSettings: FeedbackSettings): Observable<FeedbackSettings> {
    return this.http.put<FeedbackSettings>(this.baseUrl, { ...feedbackSettings });
  }
}

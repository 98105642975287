<mat-list-item>
  <div class="list-item-content">
    <mpcm-file-type-tile
      class="list-item-content__file-type-tile"
      [ngClass]="{
        'mpcm-file-type-tile--active': uploadedFile.status === UploadEntryStatus.Downloadable,
        'mpcm-file-type-tile--warn': uploadedFile.status !== UploadEntryStatus.Downloadable
      }"
      [fileType]="entryFileType"
      [matTooltip]="entryFileType | mpcmFileTypeName"
      [matTooltipPosition]="'above'"
    ></mpcm-file-type-tile>

    <div class="list-item-content__title">
      <span
        class="list-item-content__title-txt truncate"
        [title]="uploadedFile.originalFileName"
        data-test="datasource-upload-list-item__title"
        >{{ uploadedFile.originalFileName }}</span
      >
      <a
        class="list-item-content__subtitle truncate"
        [href]="originalFileDownloadUrl"
        target="_blank"
        title="Importierte Daten anzeigen"
        data-test="datasource-upload-list-item__subtitle"
        >Importierte Daten anzeigen</a
      >
    </div>
    <span
      [title]="uploadedFile.createdBy"
      class="list-item-content__creator truncate"
      data-test="datasource-upload-list-item__creator"
      >{{ uploadedFile.createdBy }}</span
    >
    <span
      *ngIf="
        uploadedFile.createdAt | humanizeRelativeDate : { whenInRange: { days: 2 } } as createdAt
      "
      class="list-item-content__time truncate"
      [title]="createdAt"
      data-test="datasource-upload-list-item__time-value"
      >{{ createdAt }}</span
    >
    <ng-container
      [ngTemplateOutlet]="status"
      [ngTemplateOutletContext]="{ $implicit: uploadedFile.status }"
    ></ng-container>

    <ng-content></ng-content>
  </div>
</mat-list-item>

<ng-template #status let-status>
  <div class="list-item-content__status-col">
    <span
      class="list-item-content__status truncate"
      [ngSwitch]="status"
      [ngClass]="{
        'list-item-content__status--red':
          status === UploadEntryStatus.Processing || status === UploadEntryStatus.Initial
      }"
      data-test="datasource-upload-list-item__status"
    >
      <ng-container *ngSwitchCase="UploadEntryStatus.Initial"> <span title="Hochgeladen">Hochgeladen</span> </ng-container>
      <ng-container *ngSwitchCase="UploadEntryStatus.Processing"> <span title="In Verarbeitung">In Verarbeitung</span> </ng-container>
      <ng-container *ngSwitchCase="UploadEntryStatus.Downloadable">
        <a
          class="list-item-content__file-download-button"
          [href]="processedFileDownloadUrl"
          target="_blank"
          mat-button
          color="accent"
          title="Herunterladen"
          data-test="datasource-upload-list-item__status-button"
        >
          <mat-icon data-test="datasource-upload-list-item__status-button-icon"
            >file_download</mat-icon
          >
          <span class="list-item-content__file-download-button-text" data-test="datasource-upload-list-item__status-button-text">Herunterladen</span>
        </a>
      </ng-container>
    </span>
  </div>
</ng-template>

<button mat-icon-button [matMenuTriggerFor]="actions" data-test="datasource-upload-list-item-actions-menu__menu-button">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #actions>
  <button
    #attachFileButton
    *ngIf="status !== UploadEntryStatus.Downloadable"
    mat-menu-item
    data-test="datasource-upload-list-item-actions-menu__add-attachment-button"
    [disabled]="status === UploadEntryStatus.Initial"
    [matTooltip]="attachFileButton.disabled ? 'Der Status dieses Eintrags muss in Verarbeitung sein.' : ''"
    [matTooltipPosition]="'above'"
    (click)="attachFile()"
  >
    <mat-icon>attach_file</mat-icon>
    <span>Anhang anfügen</span>
  </button>

  <button
    *ngIf="status !== UploadEntryStatus.Downloadable"
    mat-menu-item
    [matMenuTriggerFor]="subActionsMenu"
    data-test="datasource-upload-list-item-actions-menu__change-status-button"
  >
    <mat-icon>settings</mat-icon>
    <span>Status ändern</span>
  </button>

  <button mat-menu-item data-test="datasource-upload-list-item-actions-menu__remove-button" (click)="deleteFile()">
    <mat-icon>close</mat-icon>
    <span>Aus Liste entfernen</span>
  </button>
</mat-menu>

<mat-menu #subActionsMenu>
  <button
    *ngIf="status === UploadEntryStatus.Initial"
    mat-menu-item
    data-test="datasource-upload-list-item-actions-menu__processing-button"
    (click)="changeFileStatus(UploadEntryStatus.Processing)"
  >
    <span>In Verarbeitung</span>
  </button>

  <button
    *ngIf="status === UploadEntryStatus.Processing"
    mat-menu-item
    data-test="datasource-upload-list-item-actions-menu__uploaded-button"
    (click)="changeFileStatus(UploadEntryStatus.Initial)"
  >
    <span>Hochgeladen</span>
  </button>
</mat-menu>

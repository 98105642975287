<mpcm-article-counts-base-widget
  *ngIf="articleCounts$ | async as articleCounts"
  title="Medical Shop"
  icon="store"
  [featurePageLink]="['/medical-shop2/']"
  [data]="[
    { label: 'Alle Artikel', count: articleCounts.allArticleCount },
    { label: 'Klinikartikel', count: articleCounts.clinicArticleCount }
  ]"
  [enabledByPermission]="true"
  (searchTermChange)="handleSearch($event)"
  (featurePageNavigation)="onFeaturePageNavigation()"
/>

import { HelpCenterArticle, SearchHelpCenterArticlesResponse } from '@mp/shared/zendesk/domain';

import { HelpCenterArticleRaw, SearchHelpCenterArticlesResponseRaw } from '../models';

export function mapSearchHelpCenterArticlesResponse({
  results,
  count,
}: SearchHelpCenterArticlesResponseRaw): SearchHelpCenterArticlesResponse {
  return {
    helpCenterArticles: results.map((rawArticle: HelpCenterArticleRaw) => mapHelpCenterArticle(rawArticle)),
    articlesCount: count,
  };
}

export function mapHelpCenterArticle({ id, title, body, html_url }: HelpCenterArticleRaw): HelpCenterArticle {
  return {
    id,
    title,
    body,
    htmlUrl: html_url,
  };
}

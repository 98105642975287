<h2 class="dialog-title" matDialogTitle>Wähle den Dateninhalt der Daten aus</h2>

<div class="dialog-content" matDialogContent>
  <div class="dialog-content__options">
    <div
      *ngFor="let filetype of fileTypeOptions"
      class="option"
      [ngClass]="{
        'option--selected': isSelected(filetype)
      }"
      (click)="fileInfo.fileType = filetype"
    >
      <mpcm-file-type-tile
        class="option__file-type-tile"
        [ngClass]="{
          'mpcm-file-type-tile--active': isSelected(filetype)
        }"
        [fileType]="filetype"
      ></mpcm-file-type-tile>

      <mat-radio-button
        class="option__radio-button"
        [checked]="isSelected(filetype)"
      ></mat-radio-button>

      <span class="option__label truncate" [class.option__label--active]="isSelected(filetype)" [title]="filetype | mpcmFileTypeName">{{ filetype | mpcmFileTypeName }}</span>
    </div>
  </div>

  <mat-form-field class="dialog-content__comment">
    <textarea
      matInput
      cdkAutosizeMinRows="4"
      cdkTextareaAutosize
      [(ngModel)]="fileInfo.comment"
      placeholder="Deine Bemerkungen (Optional)"
    ></textarea>
  </mat-form-field>
</div>

<div matDialogActions [align]="'center'">
  <button
    mat-flat-button
    class="dialog-submit"
    color="accent"
    [matDialogClose]="fileInfo"
    [disabled]="!fileInfo.fileType"
  >
    Upload starten
  </button>
</div>

import { EnvironmentInjector, createEnvironmentInjector } from '@angular/core';

import { LazyComponentDetails } from '@core/shared/util';
import type { IOrganizationDetailsTabComponent, IOrganizationDetailsTabPlugin } from '@mpk/shared/util';

export const organizationDetailsTabPlugin: IOrganizationDetailsTabPlugin = {
  label: 'Einstellung',

  async getComponentDetails(
    parentInjector: EnvironmentInjector,
  ): Promise<LazyComponentDetails<IOrganizationDetailsTabComponent>> {
    const biSettingsEditFeature = await import('../pages');
    const biAnalyticsDataAccess = await import('@mp/business-intelligence/analytics/data-access');

    return {
      component: biSettingsEditFeature.BiAnalyticsContainerComponent,
      environmentInjector: createEnvironmentInjector(
        biAnalyticsDataAccess.provideBiAnalyticsDataAccess(),
        parentInjector,
      ),
    };
  },
};

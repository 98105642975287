import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'mpNewsItemTitleErrorMessage',
  standalone: true,
})
export class NewsItemTitleErrorMessagePipe implements PipeTransform {
  transform(errors: ValidationErrors): string {
    if (errors['required']) {
      return 'Bitte die Überschrift angeben.';
    }

    if (errors['maxlength']) {
      return 'Die Überschrift ist zu lang.';
    }

    return '';
  }
}

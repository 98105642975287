<div
  class="stat"
  [class]="{ 'stat--clickable': link() !== undefined }"
  [routerLink]="link()"
  [queryParams]="queryParams()"
  (click)="link() && onStatLinkClick()"
>
  <div class="stat__value" [mpCountUp]="statValue()" [options]="options()">0</div>
  <div class="stat__label">
    <span class="stat__label-text">{{ statLabel() }}</span>
    @if (link()) {
      <mat-icon class="stat__label-icon" color="accent">north_east</mat-icon>
    }
  </div>
</div>

<input
  #attachmentSelectorInput
  type="file"
  multiple
  hidden
  [accept]="supportedFileTypeExtensions"
  (change)="onFileUpload($event)"
/>

<button mat-button class="file-upload-button" [disabled]="disabled" (click)="attachmentSelectorInput.click()">
  <mat-icon class="material-icons-outlined">attach_file</mat-icon>
  Datei anhängen
</button>

import { Injectable } from '@angular/core';
import { RouterFacade } from '@core/shared/data-access';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ArticleCounts } from '../../../models';
import { ContentManagementDashboardService } from '../../services';

export interface ContentManagerWidgetState {
  articleCounts: ArticleCounts | undefined;
}

export const INITIAL_STATE: ContentManagerWidgetState = {
  articleCounts: undefined,
};

@Injectable()
export class ContentManagerWidgetStore extends ComponentStore<ContentManagerWidgetState> {
  constructor(
    private contentManagerDashboardService: ContentManagementDashboardService,
    private routerFacade: RouterFacade
  ) {
    super(INITIAL_STATE);
  }

  readonly articleCounts$: Observable<ArticleCounts | undefined> = this.select(
    (state) => state.articleCounts
  );

  readonly fetchArticleCounts = this.effect(
    (trigger$: Observable<void>): Observable<ArticleCounts> => {
      return trigger$.pipe(
        switchMap(() =>
          this.contentManagerDashboardService.fetchContentManagerArticleCounts().pipe(
            tap((articleCounts) => this.setArticleCounts(articleCounts)),
            catchError(() => EMPTY)
          )
        )
      );
    }
  );

  private readonly setArticleCounts = this.updater<ArticleCounts>(
    (state, articleCounts): ContentManagerWidgetState => ({
      ...state,
      articleCounts,
    })
  );

  navigateToArticleSearch(searchTerm?: string): void {
    this.routerFacade.navigate((router, route) =>
      router.navigate(['./artikel/list'], {
        relativeTo: route.firstChild,
        queryParams: { query: searchTerm ?? null },
      })
    );
  }
}

import { NgSwitch, NgSwitchCase, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { UploadFormStatus } from '@core/ui';

@Component({
  selector: 'mpcm-file-upload-label',
  templateUrl: './file-upload-label.component.html',
  styleUrls: ['./file-upload-label.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgSwitchCase,
    UpperCasePipe,
    NgSwitch,

    MatButtonModule,
    MatIconModule,
  ],
})
export class FileUploadLabelComponent {
  readonly componentCssClass = 'mpcm-file-upload-label';

  @HostBinding('class')
  get class(): string {
    return `${this.componentCssClass} ${this.getComponentStateCssClass()}`;
  }

  @Input() acceptedFileTypes = '*';
  @Input() status: UploadFormStatus = UploadFormStatus.PreUpload;
  @Input() errorMessage: string | undefined;

  protected readonly UploadFormStatus = UploadFormStatus;

  private getComponentStateCssClass(): string {
    let statusCssModifier = '';
    switch (this.status) {
      case UploadFormStatus.Success: {
        statusCssModifier = '--success';
        break;
      }
      case UploadFormStatus.Error: {
        statusCssModifier = '--error';
        break;
      }
      default:
        break;
    }

    return statusCssModifier ? this.componentCssClass + statusCssModifier : '';
  }
}

import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LetDirective } from '@ngrx/component';
import { Observable, tap } from 'rxjs';

import { NewsItemEditComponent, NewsItemEditData } from '@mp/dashboard/newsfeed/ui';
import { NewsItem } from '@mp/shared/kernel/newsfeed/domain';
import { UserInfoModule } from '@mp/shared/profil/data-access';

import { NewsItemEditContainerViewModel } from './news-item-edit-container-view-model';
import { NewsItemEditContainerStore } from './news-item-edit-container.store';

@Component({
  selector: 'mp-news-item-edit-container',
  standalone: true,
  templateUrl: './news-item-edit-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, LetDirective, NewsItemEditComponent, UserInfoModule],
  providers: [NewsItemEditContainerStore],
})
export class NewsItemEditContainerComponent {
  @Input() set newsItem(newsItem: NewsItem | undefined) {
    this._newsItem = newsItem;

    this.store.setNewsItemIdContext(newsItem?.newsItemId);
  }

  get newsItem(): NewsItem | undefined {
    return this._newsItem;
  }

  private _newsItem: NewsItem | undefined;

  @Output() readonly editModeClose: EventEmitter<void> = new EventEmitter<void>();

  readonly vm$: Observable<NewsItemEditContainerViewModel> = this.store.vm$;

  constructor(private readonly store: NewsItemEditContainerStore) {
    this.store.saveNewsSuccess$
      .pipe(
        tap(() => this.onEditModeClose()),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  saveNewsEntry(newsItemEditData: NewsItemEditData): void {
    if (this.newsItem) {
      this.store.updateNewsEntry(this.newsItem.newsItemId, newsItemEditData);
      return;
    }

    this.store.createNewsEntry(newsItemEditData);
  }

  deleteNewsEntry(): void {
    if (this.newsItem) {
      this.store.deleteNewsEntry(this.newsItem.newsItemId);
    }
  }

  onEditModeClose(): void {
    this.editModeClose.emit();
  }
}

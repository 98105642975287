import { EnvironmentInjector } from '@angular/core';

import { LazyComponentDetails } from '@core/shared/util';
import type { IUserDetailsTabComponent, IUserDetailsTabPlugin } from '@mpk/shared/util';

export const userDetailsTabPlugin: IUserDetailsTabPlugin = {
  label: 'Business Intelligence',

  async getComponentDetails(
    parentInjector: EnvironmentInjector,
  ): Promise<LazyComponentDetails<IUserDetailsTabComponent>> {
    const biUserSettingsFeature = await import('../components');

    return {
      component: biUserSettingsFeature.UserBiSettingsEditComponent,
      environmentInjector: parentInjector,
    };
  },
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArticleCounts } from '@mpdm/shared';

import { Observable } from 'rxjs';

@Injectable()
export class MedicalShopDashboardService {
  private readonly basePathApi = '/api/medical-shop2';

  constructor(private readonly http: HttpClient) {}

  fetchMedicalShopArticleCounts(): Observable<ArticleCounts> {
    return this.http.get<ArticleCounts>(
      `${this.basePathApi}/metrics/article-counts`
    );
  }
}

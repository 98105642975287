<div class="dropzone-label" [ngSwitch]="status">
  <ng-container>
    <mat-icon
      class="dropzone-label__icon material-icons-outlined"
      data-test="file-upload-label__dropzone-label-icon"
    >
      <ng-container *ngSwitchCase="UploadFormStatus.PreUpload"
        >backup
      </ng-container>
      <ng-container *ngSwitchCase="UploadFormStatus.Success"
        >download_done
      </ng-container>
      <ng-container *ngSwitchCase="UploadFormStatus.Error"
        >warning
      </ng-container>
    </mat-icon>
    <div class="dropzone-label__text">
      <span
        class="dropzone-label__text--bold"
        data-test="file-upload-label__dropzone-label-header"
      >
        <ng-container *ngSwitchCase="UploadFormStatus.PreUpload">{{
          acceptedFileTypes | uppercase
        }}</ng-container>
        <ng-container *ngSwitchCase="UploadFormStatus.Success"
          >Erfolgreich hochgeladen!</ng-container
        >
        <ng-container *ngSwitchCase="UploadFormStatus.Error"
          >Fehlgeschlagen!</ng-container
        >
      </span>
      <span data-test="file-upload-label__dropzone-label-subheader">
        <ng-container *ngSwitchCase="UploadFormStatus.PreUpload">
          max. 500MB pro Datei</ng-container
        >
        <ng-container *ngSwitchCase="UploadFormStatus.Success"
          >Du erhältst in Kürze eine Email.</ng-container
        >
        <ng-container *ngSwitchCase="UploadFormStatus.Error">
          {{ errorMessage }}</ng-container
        >
      </span>
    </div>
  </ng-container>
</div>

<button class="upload-button" mat-stroked-button>
  <mat-icon>post_add</mat-icon>
  <ng-container>
    <span data-test="file-upload-label__upload-button-text" [ngSwitch]="status">
      <ng-container *ngSwitchCase="UploadFormStatus.PreUpload"
        >Produktdaten importieren</ng-container
      >
      <ng-container *ngSwitchCase="UploadFormStatus.Success"
        >Weitere Produktdaten importieren</ng-container
      >
      <ng-container *ngSwitchCase="UploadFormStatus.Error"
        >Erneut Produktdaten importieren</ng-container
      >
    </span>
  </ng-container>
</button>

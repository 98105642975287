import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, combineLatest, map } from 'rxjs';

import { ZendeskApiDetails } from '../models';

@Injectable()
export class ZendeskApiDetailsService {
  private readonly _zendeskApiUrl$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private readonly _zendeskApiToken$: ReplaySubject<string> = new ReplaySubject<string>(1);

  readonly zendeskApiUrl$: Observable<string> = this._zendeskApiUrl$.asObservable();

  readonly zendeskApiDetails$: Observable<ZendeskApiDetails> = combineLatest([
    this.zendeskApiUrl$,
    this._zendeskApiToken$,
  ]).pipe(map(([url, token]) => ({ url, token })));

  setZendeskApiUrl(zendeskApiUrl: string): void {
    if (zendeskApiUrl && zendeskApiUrl.endsWith('/')) {
      zendeskApiUrl = zendeskApiUrl.slice(0, zendeskApiUrl.length - 1);
    }

    this._zendeskApiUrl$.next(zendeskApiUrl);
  }

  setZendeskApiToken(zendeskApiToken: string): void {
    this._zendeskApiToken$.next(zendeskApiToken);
  }
}

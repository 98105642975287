<mat-expansion-panel
  class="news-item-expansion-panel"
  [hideToggle]="true"
  [(expanded)]="expanded"
  (expandedChange)="onExpandedChange($event)"
>
  <mat-expansion-panel-header>
    <div class="news-header">
      <div class="news-header__title-row">
        <span class="news-header__title"> {{ newsItem.title }}</span>

        <div class="news-actions">
          <button
            *ngIf="newsItem.canModify"
            mat-icon-button
            class="news-actions__edit-button"
            (click)="switchToEditMode(); $event.stopPropagation()"
          >
            <mat-icon class="news-actions__edit-button-icon">edit</mat-icon>
          </button>

          <mat-icon class="news-actions__toggle-expansion-icon">
            {{ expanded ? 'expand_less' : 'expand_more' }}
          </mat-icon>
        </div>
      </div>

      <div class="news-header__info">
        <mp-news-item-modification-info [modifierInfo]="newsItem.creator" [modificationDate]="newsItem.createdAt">
          <mat-icon
            [svgIcon]="newsItem.isOrganizationSpecific ? 'mdi:newspaper-variant-outline' : 'global_document'"
          ></mat-icon>
        </mp-news-item-modification-info>

        <mp-news-item-modification-info
          *ngIf="newsItem.modifier && newsItem.modifiedAt"
          [modifierInfo]="newsItem.modifier"
          [modificationDate]="newsItem.modifiedAt"
        >
          <span class="modification-info-label truncate" title="Bearbeitet von">Bearbeitet von</span>
        </mp-news-item-modification-info>
      </div>
    </div>
  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>
    <quill-view-html [content]="newsItem.description" [sanitize]="false"></quill-view-html>
  </ng-template>
</mat-expansion-panel>

<button
  class="zendesk-help-center-button"
  [ngClass]="{ 'zendesk-help-center-button--small': isSmall }"
  (click)="openZendeskHelpCenterInfoFlyout()"
  matTooltip="Weiterleitung zum Hilfe Center"
  color="accent"
  mat-button
>
  <mat-icon>support</mat-icon>
  <span class="zendesk-help-center-button__label">Hilfe Center</span>
</button>

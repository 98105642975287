import { HttpEvent, HttpEventType } from '@angular/common/http';

export function extractProgressInfo<T>(event: HttpEvent<T>): number | undefined {
  switch (event.type) {
    case HttpEventType.Sent:
      return 0;

    case HttpEventType.UploadProgress:
      return event.total ? Math.round((100 * event.loaded) / event.total) : 0;

    case HttpEventType.Response:
      return 100;
  }

  // There are several events which do not require calculating of the upload progress
  // For those the undefined value is returned
  return undefined;
}

import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';

import { Actions, RechtFilter } from '@mp/shared/data-access';
import { UserInfoFacade } from '@mp/shared/profil/data-access';
import { HasRequisitionsRightsPipe } from '@mpdm/shared';

@Pipe({
  name: 'hasRequisitionsWidgetRights',
  standalone: true,
})
export class HasRequisitionsWidgetRightsPipe implements PipeTransform {
  constructor(
    private readonly userInfo: UserInfoFacade,
    private readonly hasRequisitionsRightsPipe: HasRequisitionsRightsPipe,
  ) {}

  transform(widgetRight: RechtFilter): Observable<boolean> {
    const hasWidgetRight$: Observable<boolean> = this.hasPermission(widgetRight);

    return hasWidgetRight$.pipe(
      switchMap((hasWidgetRight) =>
        hasWidgetRight ? this.hasRequisitionsRightsPipe.transform(Actions.Pass) : of(false),
      ),
    );
  }

  private hasPermission(right: RechtFilter): Observable<boolean> {
    return this.userInfo.watchRecht$(right, false);
  }
}

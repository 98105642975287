import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Subscription } from 'rxjs';

import { createAppTitle } from '@core/shared/util';
import { AppConfigService } from '@mp/shared/data-access';

@Injectable()
export class AppTitleStrategy extends TitleStrategy {
  private _subscription?: Subscription;

  constructor(
    private readonly title: Title,
    private readonly appConfigService: AppConfigService,
  ) {
    super();
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {
    this._subscription?.unsubscribe();

    const title = this.buildTitle(snapshot);

    this._subscription = this.appConfigService.envConfig$.subscribe(({ appTitle }) => {
      this.title.setTitle(title == null ? appTitle : createAppTitle(appTitle, title));
    });
  }
}

@if (profile(); as profile) {
  <mp-avatar
    [size]="size() === 'small' ? 'tiny' : 'medium'"
    [name]="profile.name"
    [image]="(profile.userData.type === UserType.IdentityUser ? profile.userData.image : null) | mpkUserImage"
  />

  @if (size() === 'large') {
    <div class="profile-user">{{ profile.name }}</div>
    <div class="profile-organization">{{ profile.activeOrganization?.name }}</div>
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NewsfeedState, newsfeedFeatureKey, selectAllNewsItems } from './newsfeed.reducer';

export const selectNewsfeedState = createFeatureSelector<NewsfeedState>(newsfeedFeatureKey);

export const selectNewsList = createSelector(selectNewsfeedState, (state) => selectAllNewsItems(state.newsList));

export const selectNewsListPaginationMetadata = createSelector(
  selectNewsfeedState,
  (state) => state.newsListPaginationMetadata,
);
export const selectIsNewsListLoading = createSelector(selectNewsfeedState, (state) => state.isNewsListLoading);

export const selectAvailableOrganisations = createSelector(
  selectNewsfeedState,
  (state) => state.availableOrganisations,
);

import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { RichTextEditorComponent } from '@core/ui';
import { NewsItem, OrganisationBasic } from '@mp/shared/kernel/newsfeed/domain';

import { NewsItemEditData } from '../../models';
import { NewsItemEditActionsComponent } from '../news-item-edit-actions/news-item-edit-actions.component';
import { NewsOrganisationsSelectorComponent } from '../news-organisations-selector/news-organisations-selector.component';

import { NewsItemDescriptionErrorMessagePipe } from './news-item-description-error-message.pipe';
import { NewsItemTitleErrorMessagePipe } from './news-item-title-error-message.pipe';

interface NewsItemEditForm {
  title: FormControl<string>;
  description: FormControl<string>;
}

@Component({
  selector: 'mp-news-item-edit',
  standalone: true,
  templateUrl: './news-item-edit.component.html',
  styleUrls: ['./news-item-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    ReactiveFormsModule,

    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,

    NewsOrganisationsSelectorComponent,
    NewsItemEditActionsComponent,
    RichTextEditorComponent,
    NewsItemTitleErrorMessagePipe,
    NewsItemDescriptionErrorMessagePipe,
  ],
})
export class NewsItemEditComponent {
  @HostBinding() readonly class = 'mp-news-item-edit';

  @Input()
  set newsItem(newsItem: NewsItem | undefined) {
    this.setupNewsItemFormData(newsItem);
    this._newsItem = newsItem;
  }

  get newsItem(): NewsItem | undefined {
    return this._newsItem;
  }

  private _newsItem: NewsItem | undefined;

  @Input() showOrganisationsSelector = false;

  @Input() selectedOrganisations: OrganisationBasic[] = [];

  @Input() availableOrganisations: OrganisationBasic[] = [];

  @Output() readonly newsSave: EventEmitter<NewsItemEditData> = new EventEmitter<NewsItemEditData>();

  @Output() readonly newsDelete: EventEmitter<void> = new EventEmitter<void>();

  @Output() readonly editModeClose: EventEmitter<void> = new EventEmitter<void>();

  readonly TITLE_MAX_LENGTH = 50;

  readonly DESCRIPTION_MAX_LENGTH = 8000;

  readonly DESCRIPTION_TEXT_CONTENT_MAX_LENGTH = this.DESCRIPTION_MAX_LENGTH / 2;

  readonly titleControl: FormControl<string> = this.fb.control('', [
    Validators.required,
    Validators.maxLength(this.TITLE_MAX_LENGTH),
  ]);

  readonly descriptionControl: FormControl<string> = this.fb.control('', [
    Validators.required,
    Validators.maxLength(this.DESCRIPTION_MAX_LENGTH),
  ]);

  readonly newsItemEditDataForm: FormGroup<NewsItemEditForm> = this.fb.group({
    title: this.titleControl,
    description: this.descriptionControl,
  });

  constructor(private readonly fb: NonNullableFormBuilder) {}

  saveNews(): void {
    this.newsSave.emit(this.getUpdateNewsItem());
  }

  deleteNews(): void {
    this.newsDelete.emit();
  }

  closeEditMode(): void {
    this.editModeClose.emit();
  }

  private getUpdateNewsItem(): NewsItemEditData {
    return {
      ...this.newsItemEditDataForm.getRawValue(),
      organizationIds: this.selectedOrganisations.map(({ organizationId }) => organizationId),
    };
  }

  private setupNewsItemFormData(newsItem: NewsItem | undefined): void {
    const { title = '', description = '' } = newsItem || {};
    this.newsItemEditDataForm.setValue({ title, description });
  }
}

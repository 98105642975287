<mp-dropdown
  [options]="prioritiesOptions"
  appearance="fill"
  [selectedValue]="ticketPriority"
  [labelTemplate]="labelTemplate"
  (selectedValueChange)="onTicketPriorityChange($event)"
>
  <ng-template #labelTemplate>
    <div class="priority-selector-label">
      Priorität
      <mat-icon
        class="priority-selector-label__info-icon"
        [matTooltip]="priorityInfoTooltipText"
        matTooltipPosition="above"
        matTooltipClass="mp-support-message-priority-selector__priority-info-tooltip"
      >
        info
      </mat-icon>
    </div>
  </ng-template>
</mp-dropdown>

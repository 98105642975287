import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FeedbackEmailSettings } from '@mp/shared/helper-links/feedback/domain';

import { MailSenderDetails } from '../../models';

import { FeedbackMailToHrefPipe } from './feedback-mail-to-href.pipe';

@Component({
  selector: 'mp-feedback-mail-to-button',
  standalone: true,
  templateUrl: './feedback-mail-to-button.component.html',
  styleUrls: ['./feedback-mail-to-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, MatIconModule, MatLegacyButtonModule, MatTooltipModule, FeedbackMailToHrefPipe],
})
export class FeedbackMailToButtonComponent {
  @Input({ required: true }) mailSenderDetails!: MailSenderDetails;

  @Input({ required: true }) feedbackEmailSettings!: FeedbackEmailSettings;

  @Input({ required: true }) isSmall!: boolean;
}

<mp-basic-flyout [title]="'Hilfe Center'" (closed)="close()">
  <div class="flyout-content">
    <div class="flyout-content__articles">
      <ng-container *ngrxLet="zendeskHelpCenterArticles$ as zendeskHelpCenterArticles; suspenseTpl: loading">
        <ng-container *ngrxLet="zendeskHelpCenterArticlesCount$ as zendeskHelpCenterArticlesCount">
          <div class="faq-header">
            <span class="faq-header__title">FAQ</span>
            <span class="faq-header__results-info">
              @if (zendeskHelpCenterArticlesCount > 0) {
                {{ zendeskHelpCenterArticlesCount }} Artikel zu diesem Thema
              } @else {
                Keine Artikel zu diesem Thema
              }
            </span>
          </div>
          <mp-zendesk-help-center-articles-list [zendeskHelpCenterArticles]="zendeskHelpCenterArticles" />
          @if (helpCenterWithArticlesLabelLink && zendeskHelpCenterArticlesCount > maximumArticlesCount) {
            <a
              class="flyout-content__more-articles-link"
              mat-stroked-button
              [href]="helpCenterWithArticlesLabelLink"
              target="_blank"
            >
              Weitere Artikel zu diesem Thema
            </a>
          }
        </ng-container>
      </ng-container>
    </div>

    @if (helpCenterLink || showCreateTicketButton) {
      <div class="flyout-content__actions">
        <mp-zendesk-help-center-flyout-actions [helpCenterLink]="helpCenterLink">
          @if (showCreateTicketButton && createTicketButtonTemplate) {
            <ng-container *ngTemplateOutlet="createTicketButtonTemplate" />
          }
        </mp-zendesk-help-center-flyout-actions>
      </div>
    }
  </div>
</mp-basic-flyout>

<ng-template #loading>
  <mp-spinner />
</ng-template>

import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';
import { Actions, RechtFilter, Resources } from '@mp/shared/data-access';
import { UserInfoModule } from '@mp/shared/profil/data-access';

import { ArticleCounts } from '../../../models';
import { ContentManagementWidgetInsightsEvent } from '../../insights';
import { ArticleCountsBaseWidgetComponent } from '../article-counts-base-widget/article-counts-base-widget.component';

import { ContentManagerWidgetStore } from './content-management-widget.store';

@Component({
  selector: 'mpcm-content-management-widget',
  standalone: true,
  templateUrl: './content-management-widget.component.html',
  styleUrl: './content-management-widget.component.scss',
  imports: [NgIf, AsyncPipe, ArticleCountsBaseWidgetComponent, UserInfoModule],
  providers: [ContentManagerWidgetStore],
})
export class ContentManagementWidgetComponent {
  readonly useContentManagementArticleRight$: RechtFilter = {
    resource: Resources.ContentManagementArticle,
    action: Actions.Use,
  } as const;

  readonly articleCounts$: Observable<ArticleCounts | undefined> = this.store.articleCounts$;

  constructor(
    private readonly store: ContentManagerWidgetStore,
    private readonly insightsEventsTrackingService: InsightsEventsTrackingService,
  ) {
    this.store.fetchArticleCounts();
  }

  handleSearch(searchTerm: string): void {
    this.insightsEventsTrackingService.trackEvent(ContentManagementWidgetInsightsEvent.SEARCH);
    this.store.navigateToArticleSearch(searchTerm);
  }

  onFeaturePageNavigation(): void {
    this.insightsEventsTrackingService.trackEvent(ContentManagementWidgetInsightsEvent.FEATURE_QUICK_ACCESS);
  }
}

import { NgModule } from '@angular/core';

import { AppInsightsDataAccessModule, AppInsightsFacade } from '@mp/shared/app-insights/data-access';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';

@NgModule({
  imports: [AppInsightsDataAccessModule],
  providers: [{ provide: InsightsEventsTrackingService, useExisting: AppInsightsFacade }],
})
export class AppInsightsFeatureModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardService {
  constructor(private readonly http: HttpClient) {}

  getCurrentOrganizationUsersCount(): Observable<number> {
    return this.http.get<number>('/api/m/kernel/users/identity/currentorganization/count');
  }
}

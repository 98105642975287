import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { InsightsEvent } from '@mp/shared/app-insights/domain';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';
import { PageLink } from '@mp/shared/data-access';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';
import { CountUpStatComponent } from '@mpcm/shared';

import {
  PendingPurchaseRequisitionCounts,
  PurchaseRequisitionFilterState,
  PurchaseRequisitionQuickAccessFilter,
  PurchaseRequisitionsPeriod,
} from '../../../models';
import { PendingRequisitionsCountWidgetInsightsEvent } from '../../insights';

import { PendingRequisitionsCountsWidgetStore } from './pending-requisition-counts-widget.store';

@Component({
  selector: 'mpdm-pending-requisition-counts-widget',
  standalone: true,
  templateUrl: './pending-requisition-counts-widget.component.html',
  styleUrl: './pending-requisition-counts-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, AsyncPipe, DashboardWidgetComponent, CountUpStatComponent],
  providers: [PendingRequisitionsCountsWidgetStore],
})
export class PendingRequisitionCountsWidgetComponent {
  readonly pendingRequisitionsCounts$: Observable<PendingPurchaseRequisitionCounts | undefined> =
    this.store.pendingPurchaseRequisitionCounts$;

  readonly link: PageLink = ['orders/document-management/purchase-requisitions'];

  readonly pendingRequisitionsParams: PurchaseRequisitionQuickAccessFilter = {
    numberOfDays: PurchaseRequisitionsPeriod.All,
    requisitionState: PurchaseRequisitionFilterState.Pending,
  };

  readonly failedPendingRequisitionsParams: PurchaseRequisitionQuickAccessFilter = {
    numberOfDays: PurchaseRequisitionsPeriod.All,
    requisitionState: PurchaseRequisitionFilterState.Failed,
  };

  protected readonly PendingRequisitionsCountWidgetInsightsEvent = PendingRequisitionsCountWidgetInsightsEvent;

  constructor(
    private readonly store: PendingRequisitionsCountsWidgetStore,
    private readonly insightsEventsTrackingService: InsightsEventsTrackingService,
  ) {
    this.store.fetchPendingPurchaseRequisitionCounts();
  }

  trackFeatureAccess(event: InsightsEvent): void {
    this.insightsEventsTrackingService.trackEvent(event);
  }
}

<ng-container *ngrxLet="vm$ as vm">
  <ng-container
    *ngTemplateOutlet="
      vm.isZendeskEnabled ? zendeskFeedback : emailFeedback;
      context: { $implicit: vm.feedbackSettings.emailSettings }
    "
  />

  <ng-template #emailFeedback let-feedbackEmailSettings>
    <mp-feedback-mail-to-button
      [mailSenderDetails]="vm.mailSenderDetails"
      [feedbackEmailSettings]="feedbackEmailSettings"
      [isSmall]="isSmall"
    />
  </ng-template>

  <ng-template #zendeskFeedback>
    <mp-zendesk-support-dialog-container #zendeskSupportDialogContainerComponent>
      <mp-zendesk-feedback-button
        [isSmall]="isSmall"
        (openZendeskDialogClick)="zendeskSupportDialogContainerComponent.openZendeskSupportDialog()"
      />
    </mp-zendesk-support-dialog-container>
  </ng-template>
</ng-container>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ArticleCounts } from '../../models';

@Injectable()
export class ContentManagementDashboardService {
  private readonly basePathApi = '/api/contentmanagement';

  constructor(private readonly http: HttpClient) {}

  fetchContentManagerArticleCounts(): Observable<ArticleCounts> {
    return this.http.get<ArticleCounts>(`${this.basePathApi}/metrics/article-counts`);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractAuthInfoService } from '@mp/shared/util';
import { ActiveOrganizationService, AuthService } from '@mpauth/shared/data-access';

/**
 * The implementation of AbstractAuthInfoService that provides the basic auth info
 * for the current user.
 */
@Injectable()
export class AuthInfoService extends AbstractAuthInfoService {
  readonly userSuccessfullyLoggedIn$: Observable<void> = this.authService.userSuccessfullyLoggedIn$;

  constructor(
    private readonly authService: AuthService,
    private readonly activeOrganizationService: ActiveOrganizationService,
  ) {
    super();
  }

  getAccessToken(): string {
    return this.authService.getAccessToken();
  }

  scheduleLogout(): void {
    this.activeOrganizationService.resetActiveOrganizationIdOnLogout();
    this.authService.logout();
  }
}

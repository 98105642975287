<ng-container *ngrxLet="vm$ as vm">
  <mp-zendesk-help-center-button
    *ngIf="vm.isZendeskEnabled; else helpCenterDirectLink"
    [isSmall]="isSmall"
    (openZendeskHelpCenterInfoFlyoutClick)="onOpenZendeskHelpCenterInfoFlyoutClick()"
  />

  <ng-template #helpCenterDirectLink>
    <mp-help-center-direct-link-button [helpCenterLink]="vm.helpCenterSettings.directLink || ''" [isSmall]="isSmall" />
  </ng-template>

  <ng-template #createTicketButtonTemplate>
    <mp-zendesk-support-dialog-container #zendeskSupportDialogContainerComponent>
      <mp-create-ticket-button
        (createTicketButtonClick)="zendeskSupportDialogContainerComponent.openZendeskSupportDialog()"
      />
    </mp-zendesk-support-dialog-container>
  </ng-template>
</ng-container>

import { Pipe, PipeTransform } from '@angular/core';
import { UploadEntryFileType } from '../models';

const FILE_TYPE_NAME_MAP: Record<UploadEntryFileType, string> = {
  [UploadEntryFileType.MasterData]: 'STAMMDATEN',
  [UploadEntryFileType.PriceData]: 'PREISDATEN',
  [UploadEntryFileType.ConsumptionData]: 'VERBRAUCHSDATEN',
  [UploadEntryFileType.SalesData]: 'UMSATZDATEN',
  [UploadEntryFileType.Other]: 'SONSTIGES',
};

@Pipe({
  name: 'mpcmFileTypeName',
  standalone: true,
})
export class FileTypeNamePipe implements PipeTransform {
  transform(fileType: UploadEntryFileType): string {
    return FILE_TYPE_NAME_MAP[fileType] || '';
  }
}

import { NgModule } from '@angular/core';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { FileInfoDialogComponent } from './file-info-dialog.component';

const DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
  panelClass: 'mpcm-file-info-dialog-container',
};

@NgModule({
  imports: [FileInfoDialogComponent],
  exports: [FileInfoDialogComponent],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: DEFAULT_DIALOG_CONFIG }],
})
export class FileInfoDialogModule {}

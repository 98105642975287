import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';

import { LoginContextService } from '@mpk/shared/data-access';

import { NavigationGroup } from './navigation-item';

export function redirectToFirstChild(navigationGroup: NavigationGroup): CanActivateFn {
  return (): UrlTree => {
    const loginContextService = inject(LoginContextService);
    const router = inject(Router);

    const loginContext = loginContextService.loginContext();

    for (const child of navigationGroup.children) {
      if (child.permission == null || loginContext.hasPermission(child.permission)) {
        return router.createUrlTree([navigationGroup.route, child.route]);
      }
    }

    return router.createUrlTree(['error']);
  };
}

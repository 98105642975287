import { NgModule } from '@angular/core';
import { DatasourceUploadWidgetComponent } from './components';
import { DatasourceUploadFacade, DatasourceUploadService } from './services';

@NgModule({
  imports: [DatasourceUploadWidgetComponent],
  exports: [DatasourceUploadWidgetComponent],
  providers: [DatasourceUploadService, DatasourceUploadFacade],
})
export class DatasourceUploadWidgetModule {}

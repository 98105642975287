import { DestroyRef, Injectable, NgZone, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Subject, fromEvent, interval, of, throwError } from 'rxjs';
import { defaultIfEmpty, filter, first, map, takeUntil, tap } from 'rxjs/operators';

import { ZendeskApiDetailsService } from '@mp/shared/zendesk/util';

import { ZENDESK_OAUTH_SCOPES } from '../injection-tokens';

interface ZendeskAccessTokenMessage {
  accessToken: string;
  type: 'ZENDESK_ACCESS_TOKEN';
}

@Injectable()
export class ZendeskAuthService {
  private get baseUrl(): string {
    return this.zendeskApiUrl || '';
  }

  private zendeskApiUrl: string | null = null;

  private readonly ZENDESK_ACCESS_TOKEN_MESSAGE_TYPE = 'ZENDESK_ACCESS_TOKEN';

  private readonly REDIRECT_URI: string = `${location.origin}/assets/zendesk/zendesk.html`;

  private readonly AUTH_SCOPE: string = inject(ZENDESK_OAUTH_SCOPES);

  constructor(
    private readonly zendeskApiDetailsService: ZendeskApiDetailsService,
    private readonly ngZone: NgZone,
    private readonly destroyRef: DestroyRef,
  ) {
    this.zendeskApiDetailsService.zendeskApiUrl$
      .pipe(
        tap((zendeskUrl) => (this.zendeskApiUrl = zendeskUrl)),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  /**
   * Gets an access token for the Zendesk API. It runs the implicit grant flow in spawned popup window.
   * @param clientId - An OAuth client ID for Zendesk access.
   */
  getAuthToken(clientId: string): Observable<string | null> {
    if (!this.zendeskApiUrl) {
      return this.throwNoZendeskApiUrlError();
    }

    return this.runTokenImplicitGrantFlow(clientId);
  }

  private runTokenImplicitGrantFlow(clientId: string): Observable<string | null> {
    const zendeskAuthWindow = this.openAuthFlowWindow(clientId);

    if (!zendeskAuthWindow) return of(null);

    return this.listenForAccessTokenMessage(zendeskAuthWindow);
  }

  private openAuthFlowWindow(clientId: string): Window | null {
    const endpoint = `${this.baseUrl}/oauth/authorizations/new`;

    const endpointParams: URLSearchParams = new URLSearchParams({
      response_type: 'token',
      redirect_uri: this.REDIRECT_URI,
      client_id: clientId,
      scope: this.AUTH_SCOPE,
    });

    return window.open(`${endpoint}?${endpointParams.toString()}`, undefined, 'width=800,height=600,popup');
  }

  private listenForAccessTokenMessage(zendeskAuthWindow: Window): Observable<string | null> {
    const resultSubject$: Subject<string | null> = new Subject<string | null>();

    this.ngZone.runOutsideAngular(() =>
      fromEvent<MessageEvent<ZendeskAccessTokenMessage>>(window, 'message')
        .pipe(
          filter((message) => this.isZendeskAccessTokenMessage(message, zendeskAuthWindow)),
          first(),
          map(({ data }) => data.accessToken),
          takeUntil(this.getWindowClosedObservable(zendeskAuthWindow)),
          defaultIfEmpty(null),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((accessToken) => this.ngZone.run(() => resultSubject$.next(accessToken))),
    );

    return resultSubject$.asObservable().pipe(first());
  }

  private isZendeskAccessTokenMessage({ source, origin, data }: MessageEvent, zendeskAuthWindow: Window): boolean {
    return (
      source === zendeskAuthWindow &&
      origin === location.origin &&
      data?.type === this.ZENDESK_ACCESS_TOKEN_MESSAGE_TYPE &&
      typeof data.accessToken === 'string'
    );
  }

  private getWindowClosedObservable(zendeskAuthWindow: Window): Observable<void> {
    const WINDOW_CLOSE_CHECK_INTERVAL = 200;

    return interval(WINDOW_CLOSE_CHECK_INTERVAL).pipe(
      map(() => zendeskAuthWindow.closed),
      filter(Boolean),
      map(() => undefined),
      first(),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  private throwNoZendeskApiUrlError(): Observable<never> {
    return throwError(() => new Error('Zendesk API URL not available'));
  }
}

import { HttpEvent } from '@angular/common/http';
import { filterUndefined } from '@core/shared/util';
import { last, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { extractProgressInfo } from '../utils';

export function reportProgress<T extends HttpEvent<object>>(
  onProgress?: (progress: number) => void
): (source$: Observable<T>) => Observable<void> {
  return (source$: Observable<T>) =>
    source$.pipe(
      map((event) => extractProgressInfo(event)),
      filterUndefined(),
      tap(onProgress),
      last(),
      map(() => undefined)
    );
}

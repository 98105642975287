import { Pipe, PipeTransform } from '@angular/core';

import { FeedbackEmailSettings } from '@mp/shared/helper-links/feedback/domain';
import { replaceMailBodyVariables } from '@mp/shared/helper-links/feedback/util';

import { MailSenderDetails } from '../../models';

@Pipe({
  name: 'mpFeedbackMailToHref',
  standalone: true,
})
export class FeedbackMailToHrefPipe implements PipeTransform {
  transform(emailFeedback: FeedbackEmailSettings, mailSenderDetails: MailSenderDetails): unknown {
    const { recipients, subject, body, cc }: FeedbackEmailSettings = emailFeedback;
    const transformedBody: string = replaceMailBodyVariables(body, mailSenderDetails);
    return `mailto:${recipients || ''}?subject=${subject || ''}&body=${encodeURIComponent(transformedBody)}&cc=${cc || ''}`;
  }
}

<span class="help-center-actions-title">Deine Frage kann damit nicht beantwortet werden?</span>

@if (helpCenterLink()) {
  <a class="help-center-actions-button" mat-button [href]="helpCenterLink()" target="_blank">
    <mat-icon class="help-center-actions-button__icon">support</mat-icon>
    Hilfe Center öffnen
  </a>
}

<ng-content />

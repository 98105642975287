import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { DashboardService, MyOrganizationWidgetData } from '@mp/dashboard/data-access';
import { Actions, RechtFilter, Resources } from '@mp/shared/data-access';
import { UserInfoFacade } from '@mp/shared/profil/data-access';

@Injectable()
export class DashboardFacade {
  private readonly _myOrganizationWidgetData$ = new BehaviorSubject(undefined as MyOrganizationWidgetData | undefined);
  readonly myOrganizationWidgetData$ = this._myOrganizationWidgetData$.asObservable();

  private readonly readMyOrganizationRight: RechtFilter = {
    resource: Resources.MyOrganisationWidget,
    action: Actions.Read,
  } as const;

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly userInfo: UserInfoFacade,
  ) {}

  loadAllWidgetData(): void {
    this.getMyOrganizationWidgetData();
  }

  getMyOrganizationWidgetData(): void {
    if (!this.userInfo.hasRecht(this.readMyOrganizationRight)) {
      return;
    }

    this.dashboardService
      .getCurrentOrganizationUsersCount()
      .pipe(map((usersCount) => ({ usersCount }) as MyOrganizationWidgetData))
      .subscribe({ next: (widgetData) => this._myOrganizationWidgetData$.next(widgetData) });
  }
}

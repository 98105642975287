import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';

import { DashboardService, MyOrganizationWidgetData } from '@mp/dashboard/data-access';
import { NewsfeedComponent } from '@mp/dashboard/newsfeed/feature';
import { Actions, RechtFilter, Resources } from '@mp/shared/data-access';
import { UserInfoModule } from '@mp/shared/profil/data-access';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';
import { ContentManagementDashboardModule, DatasourceUploadWidgetModule } from '@mpcm/shared';
import { DocumentManagementDashboardModule, HasRequisitionsRightsPipe } from '@mpdm/shared';
import { MedicalShopDashboardModule } from '@mpms/shared';

import { DashboardFacade } from './dashboard.facade';
import { HasRequisitionsWidgetRightsPipe } from './has-requisitions-widget-rights.pipe';
import { MyOrganizationWidgetComponent } from './widgets/my-organization/my-organization-widget.component';

@Component({
  selector: 'mp-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,

    MatIconModule,

    UserInfoModule,
    DatasourceUploadWidgetModule,
    DashboardWidgetComponent,
    ContentManagementDashboardModule,
    DocumentManagementDashboardModule,
    MedicalShopDashboardModule,
    HasRequisitionsWidgetRightsPipe,
    MyOrganizationWidgetComponent,
    NewsfeedComponent,
  ],
  providers: [DashboardFacade, DashboardService, HasRequisitionsRightsPipe],
})
export class DashboardComponent {
  readonly myOrganizationWidgetData$: Observable<MyOrganizationWidgetData | undefined> =
    this.facade.myOrganizationWidgetData$;

  readonly readOrganizationRights: RechtFilter = {
    resource: Resources.MyOrganisationWidget,
    action: Actions.Read,
  } as const;

  readonly showContentManagementWidgetRight: RechtFilter = {
    resource: Resources.ContentManagementArticle,
    action: Actions.Read,
  } as const;
  readonly showDatasourceUploadWidgetRight: RechtFilter = {
    resource: Resources.FileUploadCM,
    action: Actions.Use,
  };
  readonly showMedicalShopWidgetRight: RechtFilter = {
    resource: Resources.MedicalShopWidget,
    action: Actions.Show,
  } as const;
  readonly showPendingRequisitionsWidgetRight: RechtFilter = {
    resource: Resources.PendingRequisitionsWidget,
    action: Actions.Show,
  } as const;
  readonly showRequisitionsHistoryWidgetRight: RechtFilter = {
    resource: Resources.RequisitionsHistoryWidget,
    action: Actions.Show,
  } as const;

  constructor(private readonly facade: DashboardFacade) {
    this.facade.loadAllWidgetData();
  }
}

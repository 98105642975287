import { Injectable } from '@angular/core';
import { UploadSchedulerService } from '@core/ui';
import { Observable, Subject } from 'rxjs';
import { DatasourceUploadTriggerOptions } from '../../models';

@Injectable()
export class DatasourceUploadSchedulerService extends UploadSchedulerService<DatasourceUploadTriggerOptions> {
  private readonly _uploadTrigger$: Subject<DatasourceUploadTriggerOptions> =
    new Subject<DatasourceUploadTriggerOptions>();

  readonly uploadTrigger$: Observable<DatasourceUploadTriggerOptions> =
    this._uploadTrigger$.asObservable();

  triggerUpload(uploadTriggerOptions: DatasourceUploadTriggerOptions): void {
    this._uploadTrigger$.next(uploadTriggerOptions);
  }
}

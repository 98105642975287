import { NgClass, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FileInfo, UploadEntryFileType } from '../../models';
import { FileTypeNamePipe } from '../../pipes';
import { FileTypeTileComponent } from '../file-type-tile/file-type-tile.component';

@Component({
  selector: 'mpcm-file-info-dialog',
  templateUrl: './file-info-dialog.component.html',
  styleUrls: ['./file-info-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgFor,
    NgClass,
    FormsModule,

    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    MatInputModule,

    FileTypeTileComponent,
    FileTypeNamePipe,
  ],
})
export class FileInfoDialogComponent {
  fileInfo: Partial<FileInfo> = {};

  readonly fileTypeOptions = [
    UploadEntryFileType.MasterData,
    UploadEntryFileType.PriceData,
    UploadEntryFileType.ConsumptionData,
    UploadEntryFileType.SalesData,
    UploadEntryFileType.Other,
  ] as const;

  isSelected(fileType: UploadEntryFileType): boolean {
    return this.fileInfo.fileType === fileType;
  }
}

import { Injectable, Signal, computed } from '@angular/core';

import { AbstractLoginContextProviderService } from '@mp/kernel/auth/data-access';
import { ProfileFacade } from '@mpauth/shared/data-access';
import { Profile } from '@mpauth/shared/domain';
import { LoginContext, OrganizationContext, Permission, UserContext } from '@mpk/shared/domain';

/**
 * Implementation of LoginContext after successful login.
 */
class LoginContextImpl implements LoginContext {
  constructor(private readonly profile: Profile) {
    const { userId, name, activeOrganization } = profile;

    // Just to make the compiler happy. This will not happen.
    if (!activeOrganization) {
      throw new Error('Creating the LoginContext without organization is not supported.');
    }

    this.userContext = Object.freeze<UserContext>({
      userId: userId,
      name: name,
    });

    this.organizationContext = Object.freeze<OrganizationContext>({
      organizationId: activeOrganization.organizationId,
      name: activeOrganization.name,
    });
  }

  readonly userContext: UserContext;

  readonly organizationContext: OrganizationContext;

  hasPermission(permission: Permission): boolean {
    return this.profile.permissions.some((p) => p.resource === permission.resource && p.action === permission.action);
  }
}

/**
 * Implementation of LoginContext when there is no user with active organization available.
 * As this is only the case during init/when fetching the profile, it is ok to throw errors here.
 * The login context is not supposed to be used there.
 */
class EmptyLoginContextImpl implements LoginContext {
  static readonly Instance: LoginContext = new EmptyLoginContextImpl();

  private constructor() {}

  get userContext(): UserContext {
    throw new Error('Unauthorized');
  }
  get organizationContext(): OrganizationContext {
    throw new Error('Unauthorized');
  }
  hasPermission(): boolean {
    return false;
  }
}

/**
 * The implementation of AbstractLoginContextProviderService that provides the LoginContext
 * from the current user profile.
 */
@Injectable()
export class LoginContextProviderService extends AbstractLoginContextProviderService {
  constructor(profilFacade: ProfileFacade) {
    super();

    this.loginContext = computed(() => {
      const profile = profilFacade.profile();

      // User not fully logged in
      if (profile?.activeOrganization == null) {
        return EmptyLoginContextImpl.Instance;
      }

      return new LoginContextImpl(profile);
    });
  }

  override loginContext: Signal<LoginContext>;
}

import { Injectable } from '@angular/core';
import { RouterFacade } from '@core/shared/data-access';
import { ArticleCounts } from '@mpdm/shared';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { MedicalShopDashboardService } from '../../services';

export interface MedicalShopWidgetState {
  articleCounts: ArticleCounts | undefined;
}

export const INITIAL_STATE: MedicalShopWidgetState = {
  articleCounts: undefined,
};

@Injectable()
export class MedicalShopWidgetStore extends ComponentStore<MedicalShopWidgetState> {
  constructor(
    private medicalShopDashboardService: MedicalShopDashboardService,
    private routerFacade: RouterFacade
  ) {
    super(INITIAL_STATE);
  }

  readonly articleCounts$: Observable<ArticleCounts | undefined> = this.select(
    (state) => state.articleCounts
  );

  readonly fetchArticleCounts = this.effect(
    (trigger$: Observable<void>): Observable<ArticleCounts> => {
      return trigger$.pipe(
        switchMap(() =>
          this.medicalShopDashboardService.fetchMedicalShopArticleCounts().pipe(
            tap((articleCounts) => this.setArticleCounts(articleCounts)),
            catchError(() => EMPTY)
          )
        )
      );
    }
  );

  private readonly setArticleCounts = this.updater<ArticleCounts>(
    (state, articleCounts): MedicalShopWidgetState => ({
      ...state,
      articleCounts,
    })
  );

  navigateToArticleSearch(searchTerm?: string): void {
    this.routerFacade.navigate((router, route) =>
      router.navigate(['./medical-shop2/list'], {
        relativeTo: route.firstChild,
        queryParams: { query: searchTerm ?? null },
      })
    );
  }
}

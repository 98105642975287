<mat-card class="widget">
  <div class="widget-header">
    @if (icon) {
      <mat-icon class="widget-header__icon material-icons-outlined" [mpIcon]="icon" />
    }

    <h3 class="widget-header__title truncate">{{ widgetTitle }}</h3>

    @if (showSearchField) {
      <div class="widget-header__search-field-container" [title]="actionTitle">
        <mp-search-field
          [disabled]="!enabledByPermission"
          #searchField
          class="raised"
          placeholder="Durchsuchen"
          (searched)="handleSearch($event)"
          (valueChange)="handleSearchTermChange($event)"
        >
          @if (searchField.value.length > 0) {
            <button mat-icon-button mpSearchFieldButton="right" (click)="searchField.clear()">
              <mat-icon>clear</mat-icon>
            </button>
          }

          <button
            mat-icon-button
            mpSearchFieldButton="right"
            [disabled]="searchField.value.length < 2"
            (click)="searchField.search(searchField.value)"
          >
            <mat-icon>search</mat-icon>
          </button>
        </mp-search-field>
      </div>
    }

    <ng-content select="widget-header-element" />

    @if (featurePageLink) {
      <a
        mat-icon-button
        class="widget-header__link-button"
        color="accent"
        [title]="actionTitle"
        [disabled]="!enabledByPermission"
        (click)="onLinkClick()"
      >
        <mat-icon>north_east</mat-icon>
      </a>
    }
  </div>

  <div class="widget-content">
    <ng-content />
  </div>

  <ng-content select="[footer]" />
</mat-card>

import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

import { Pagination, PaginationMetadata } from '@core/shared/domain';
import { CreateNewsItem, ListNewsItem, NewsItem, OrganisationBasic } from '@mp/shared/kernel/newsfeed/domain';

import {
  NewsfeedActions,
  selectAvailableOrganisations,
  selectIsNewsListLoading,
  selectNewsList,
  selectNewsListPaginationMetadata,
} from '../store';

@Injectable()
export class NewsfeedFacade {
  readonly newsList$: Observable<ListNewsItem[]> = this.store$.select(selectNewsList);

  readonly newsListPaginationMetadata$: Observable<PaginationMetadata | undefined> = this.store$.select(
    selectNewsListPaginationMetadata,
  );

  readonly isNewsListLoading$: Observable<boolean> = this.store$.select(selectIsNewsListLoading);

  readonly availableOrganisations$: Observable<OrganisationBasic[]> = this.store$.select(selectAvailableOrganisations);

  readonly createNewsSuccess$: Observable<NewsItem> = this.actions$.pipe(
    ofType(NewsfeedActions.createNewsEntrySuccess),
    map(({ createdNews }) => createdNews),
  );

  readonly updateNewsSuccess$: Observable<NewsItem> = this.actions$.pipe(
    ofType(NewsfeedActions.updateNewsEntrySuccess),
    map(({ updatedNews }) => updatedNews),
  );

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
  ) {}

  fetchNewsList(pagination?: Partial<Pagination>): void {
    this.store$.dispatch(NewsfeedActions.fetchNewsList({ pagination }));
  }

  fetchAvailableOrganisations(): void {
    this.store$.dispatch(NewsfeedActions.fetchAvailableOrganisations());
  }

  createNewsEntry(createNews: CreateNewsItem): void {
    this.store$.dispatch(NewsfeedActions.createNewsEntry({ createNews }));
  }

  updateNewsEntry(newsItemId: string, updateNews: CreateNewsItem): void {
    this.store$.dispatch(NewsfeedActions.updateNewsEntry({ newsItemId, updateNews }));
  }

  deleteNewsEntry(newsItemId: string): void {
    this.store$.dispatch(NewsfeedActions.deleteNewsEntry({ newsItemId }));
  }
}

import { EnvironmentInjector } from '@angular/core';

import { LazyComponentDetails } from '@core/shared/util';
import type {
  ICurrentOrganizationUserDetailsTabComponent,
  ICurrentOrganizationUserDetailsTabPlugin,
} from '@mpk/shared/util';

export const currentOrganizationUserDetailsTabPlugin: ICurrentOrganizationUserDetailsTabPlugin = {
  label: 'Kostenstellen',

  async getComponentDetails(
    parentInjector: EnvironmentInjector,
  ): Promise<LazyComponentDetails<ICurrentOrganizationUserDetailsTabComponent>> {
    const userCostCentersAssignmentFeature = await import('../components');

    return {
      component: userCostCentersAssignmentFeature.UserCostCentersAssignmentComponent,
      environmentInjector: parentInjector,
    };
  },
};

import { Injectable, NgZone, OnDestroy, Optional } from '@angular/core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { GlobalErrorHandlerService } from '@core/shared/util';
import { CustomAngularPlugin } from '@mp/shared/app-insights/util';
import { AppConfigService, AppInsightsConfig } from '@mp/shared/data-access';

@Injectable()
export class AppInsightsService implements OnDestroy {
  private _appInsightsInstance?: ApplicationInsights;

  private _appInsightsErrorHandlerInstance?: ApplicationinsightsAngularpluginErrorService;

  private appInsights$: Observable<ApplicationInsights> = this.appConfigService.appInsightsConfig$.pipe(
    map((appInsightsConfig) => this.createAppInsights(appInsightsConfig)),
    tap((appInsights) => {
      this.addAppInsightsErrorHandler();
      this.loadAppInsights(appInsights);
    }),
    shareReplay(1),
  );

  constructor(
    private ngZone: NgZone,
    private appConfigService: AppConfigService,
    private customAngularPlugin: CustomAngularPlugin,
    @Optional() private globalErrorHandler?: GlobalErrorHandlerService,
  ) {
    // Init application insights upon service creation
    this.appInsights$.subscribe();
  }

  ngOnDestroy() {
    this.destroyAppInsights();
    this.removeAppInsightsErrorHandler();
  }

  trackEvent(name: string, customProperties?: { [key: string]: unknown }): void {
    this.dispatchInsightsAction((appInsights) => appInsights.trackEvent({ name }, customProperties));
  }

  private createAppInsights(insightsConfig: AppInsightsConfig): ApplicationInsights {
    this.customAngularPlugin.initializePlugin();

    this._appInsightsInstance = new ApplicationInsights({
      config: {
        connectionString: insightsConfig.connectionString,
        autoTrackPageVisitTime: true,
        disableAjaxTracking: true,
        disableFetchTracking: true,
        extensions: [this.customAngularPlugin],
        extensionConfig: {
          [this.customAngularPlugin.identifier]: {
            router: this.customAngularPlugin.router,
          },
        },
      },
    });

    return this._appInsightsInstance;
  }

  private loadAppInsights(appInsights: ApplicationInsights): void {
    this.ngZone.runOutsideAngular(() => appInsights.loadAppInsights());
  }

  private destroyAppInsights(): void {
    if (!this._appInsightsInstance) {
      return;
    }

    this._appInsightsInstance.unload();
    this._appInsightsInstance = undefined;
  }

  private dispatchInsightsAction(action: AppInsightsAction): void {
    this.appInsights$.subscribe((appInsights) => this.ngZone.runOutsideAngular(() => action(appInsights)));
  }

  private addAppInsightsErrorHandler() {
    if (this.globalErrorHandler) {
      this._appInsightsErrorHandlerInstance = new ApplicationinsightsAngularpluginErrorService();
      this.globalErrorHandler.addErrorHandler(this._appInsightsErrorHandlerInstance);
    }
  }

  private removeAppInsightsErrorHandler() {
    if (!this._appInsightsErrorHandlerInstance) {
      return;
    }

    this.globalErrorHandler?.removeErrorHandler(this._appInsightsErrorHandlerInstance);

    this._appInsightsErrorHandlerInstance = undefined;
  }
}

type AppInsightsAction = (appInsights: ApplicationInsights) => void;

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { FilesChangeEvent, UploadFormComponent } from '@core/ui';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';
import { DatasourceUploadTriggerOptions } from '../../models';
import { FileInfoDialogModule } from '../file-info-dialog/file-info-dialog.module';
import { FileUploadLabelComponent } from '../file-upload-label/file-upload-label.component';
import { FileUploadHandlerViewModel } from './file-upload-handler-view-model';
import { FileUploadHandlerStore } from './file-upload-handler.store';

@Component({
  selector: 'mpcm-file-upload-handler',
  templateUrl: './file-upload-handler.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LetDirective,
    MatSnackBarModule,

    FileUploadLabelComponent,
    UploadFormComponent,
    FileInfoDialogModule,
  ],
  providers: [FileUploadHandlerStore],
})
export class FileUploadHandlerComponent {
  @HostBinding('class') class = 'mpcm-file-upload-handler';

  @Input() acceptedFileTypes = '*';
  @Input() maxFileBytes = 0;
  @Input() disabled = false;

  readonly vm$: Observable<FileUploadHandlerViewModel> = this.store.vm$;

  @Output() readonly fileUploadComplete: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly store: FileUploadHandlerStore) {}

  onFilesChange(event: FilesChangeEvent): void {
    // File upload can be scheduled either by a direct click or by the upload trigger.
    // Attachment file upload is scheduled by trigger and only then there are additional options
    event.uploadTriggerOptions
      ? this.store.handleAttachmentFileUploadEvent(
          event as FilesChangeEvent<DatasourceUploadTriggerOptions>
        )
      : this.store.handleFileUploadEvent(event);
  }
}

export const IMAGE_EXTENSIONS: string[] = ['bmp', 'gif', 'jpg', 'jpeg', 'png', 'svg'];
export const DOC_EXTENSIONS: string[] = ['csv', 'doc', 'docx', 'pdf', 'txt', 'xml', 'xls', 'xlsx', 'xslm'];
export const ARCHIVE_EXTENSIONS: string[] = ['rar', 'zip', '7z'];
export const VIDEO_EXTENSIONS: string[] = ['avi', 'm4v', 'mkv', 'mp4', 'mpeg'];
export const AUDIO_EXTENSIONS: string[] = ['mp3', 'wav'];

export const ALL_SUPPORTED_FILE_TYPE_EXTENSIONS: string[] = [
  ...IMAGE_EXTENSIONS,
  ...DOC_EXTENSIONS,
  ...ARCHIVE_EXTENSIONS,
  ...VIDEO_EXTENSIONS,
  ...AUDIO_EXTENSIONS,
];

export const INPUT_SUPPORTED_FILE_TYPE_EXTENSIONS: string = ALL_SUPPORTED_FILE_TYPE_EXTENSIONS.map(
  (extension) => `.${extension}`,
).join(',');

<mp-shell [appTitle]="appTitle$ | async" [showAppLogo]="showAppLogo$ | async" [(isOpened)]="isSideNavOpened">
  <mp-nav-profile [size]="profileSize()" (logout)="logout()">
    <mpauth-profile-display [profile]="profile()" [size]="profileSize()" />

    @if (hasProfileMultipleAvailableOrganizations()) {
      <button mat-menu-item [routerLink]="['active-organization-selection']">
        <mat-icon>swap_horiz</mat-icon>
        <span>Organisation wechseln</span>
      </button>
    }
  </mp-nav-profile>

  @if (!isSideNavOpened()) {
    <div class="navigation-menu-items-separator">
      <mat-divider class="navigation-menu-items-separator__divider" />
    </div>
  }

  <div class="navigation-menu-items" [ngClass]="{ 'navigation-menu-items--collapsed': !isSideNavOpened() }">
    <mp-nav-item
      path="/"
      icon="home"
      [matTooltip]="!isSideNavOpened() ? 'Start' : ''"
      matTooltipClass="mp-app-layout__navigation-menu-tooltip"
      matTooltipPosition="right"
    >
      Start
    </mp-nav-item>

    @for (navigationItem of navigationStructure(); track $index) {
      <mp-nav-item
        [path]="navigationItem.route"
        [icon]="navigationItem.icon"
        [label]="navigationItem.label"
        [isGroup]="navigationItem.children"
        [matTooltip]="!isSideNavOpened() && !navigationItem.children ? navigationItem.label : null"
        matTooltipClass="mp-app-layout__navigation-menu-tooltip"
        matTooltipPosition="right"
      >
        @for (childItem of navigationItem.children ?? []; track $index) {
          <mp-nav-item
            [path]="navigationItem.route + '/' + childItem.route"
            [icon]="childItem.icon"
            [label]="childItem.label"
            [addChildItemLine]="isSideNavOpened()"
          />
        }
      </mp-nav-item>
    }
  </div>

  @if (!isSideNavOpened() && hasHelperFunctions) {
    <div class="navigation-menu-items-separator">
      <mat-divider class="navigation-menu-items-separator__divider" />
    </div>
  }

  <mp-helper-links-container
    class="navigation-menu-items__helper-links-container"
    sidebar-end-element
    [isSmall]="!isSideNavOpened()"
    (hasHelperFunctions)="hasHelperFunctions = $event"
  />
</mp-shell>

import { NgModule } from '@angular/core';
import { ContentManagementWidgetComponent } from './components';
import { ContentManagementDashboardService } from './services';

@NgModule({
  imports: [ContentManagementWidgetComponent],
  exports: [ContentManagementWidgetComponent],
  providers: [ContentManagementDashboardService],
})
export class ContentManagementDashboardModule {}

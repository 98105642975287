import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, defaultIfEmpty } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { filterNil } from '@core/shared/util';
import { ProfileFacade } from '@mpauth/shared/data-access';
import { Profile } from '@mpauth/shared/domain';

@Injectable({ providedIn: 'root' })
export class ProfileVerifyService {
  constructor(
    private readonly router: Router,
    private readonly profileFacade: ProfileFacade,
  ) {}

  verifyProfileConfig(): Observable<boolean> {
    this.profileFacade.fetchProfile();

    return this.profileFacade.profile$.pipe(
      filterNil(),
      map((profile) => this.checkProfileConfiguration(profile)),
      defaultIfEmpty(false),
      first(),
    );
  }

  private checkProfileConfiguration(profile: Profile): boolean {
    if (profile.availableOrganizations.length === 0) {
      this.redirectToPage('/no-organization');
      return false;
    }

    if (!profile.activeOrganization) {
      this.redirectToPage('/active-organization-selection');
      return false;
    }

    return true;
  }

  private redirectToPage(path: string): void {
    this.router.navigate([path], { replaceUrl: true });
  }
}

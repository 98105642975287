import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { getFeatureNameFromAppTitle } from '@core/shared/util';
import { InsightsEvent, InsightsEventProperties } from '@mp/shared/app-insights/domain';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';

import { AppInsightsService } from './app-insights.service';

@Injectable()
export class AppInsightsFacade implements InsightsEventsTrackingService {
  constructor(
    private readonly appInsightsService: AppInsightsService,
    private readonly titleService: Title,
  ) {}

  trackEvent(event: InsightsEvent, customProperties: InsightsEventProperties = {}): void {
    const currentPageName: string = getFeatureNameFromAppTitle(this.titleService.getTitle());
    const baseEventName = `${currentPageName.replace(' |', '')} | ${event}`;

    this.appInsightsService.trackEvent(baseEventName, customProperties);
  }
}

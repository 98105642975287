import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';

import { RechtFilter } from '@mp/shared/data-access';
import { LoginContextService } from '@mpk/shared/data-access';

export function requirePermission(permission: RechtFilter): CanActivateFn {
  return (): boolean | UrlTree => {
    const loginContextService = inject(LoginContextService);
    const router = inject(Router);

    return loginContextService.loginContext().hasPermission(permission) || router.createUrlTree(['error']);
  };
}

import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DatePipesModule } from '@core/shared/util';
import { ApiUrlService } from '@mp/shared/data-access';

import { UploadEntry, UploadEntryFileType, UploadEntryStatus } from '../../models';
import { FileTypeNamePipe } from '../../pipes';
import { FileTypeTileComponent } from '../file-type-tile/file-type-tile.component';

@Component({
  standalone: true,
  imports: [
    NgSwitchCase,
    NgSwitch,
    NgSwitchDefault,
    NgIf,
    NgTemplateOutlet,
    NgClass,

    MatListModule,
    MatButtonModule,
    MatIconModule,

    DatePipesModule,
    FileTypeTileComponent,
    MatTooltipModule,
    FileTypeNamePipe,
  ],
  selector: 'mpcm-datasource-upload-list-item',
  templateUrl: './datasource-upload-list-item.component.html',
  styleUrls: ['./datasource-upload-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatasourceUploadListItemComponent {
  @HostBinding('class') readonly class = 'mpcm-datasource-upload-list-item';

  @Input() uploadedFile!: UploadEntry;

  readonly UploadEntryStatus = UploadEntryStatus;

  constructor(private readonly apiUrlService: ApiUrlService) {}

  get entryFileType(): UploadEntryFileType {
    return this.uploadedFile.fileType ?? UploadEntryFileType.Other;
  }

  get originalFileDownloadUrl() {
    return this.apiUrlService.getApiUrl(
      `api/contentmanagement/upload/entries/${this.uploadedFile.uploadEntryId}/original`,
      true,
    );
  }

  get processedFileDownloadUrl() {
    return this.uploadedFile.status === UploadEntryStatus.Downloadable
      ? this.apiUrlService.getApiUrl(
          `api/contentmanagement/upload/entries/${this.uploadedFile.uploadEntryId}/processed`,
          true,
        )
      : null;
  }
}

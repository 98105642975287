import { UploadEntryFileType } from './file-info';

export interface UploadEntry {
  /**
   * Unique Identifier of the file.
   */
  uploadEntryId: string;
  /**
   * Original file name of the updated file
   */
  originalFileName: string;
  /**
   * The type of the data within the file.
   */
  fileType: UploadEntryFileType;
  /**
   * Status of the entry.
   */
  status: UploadEntryStatus;
  /**
   * File creation time.
   */
  createdAt: string;
  /**
   * First and last name of the creator.
   */
  createdBy: string;
}

export enum UploadEntryStatus {
  Initial = 'Initial',
  Processing = 'Processing',
  Downloadable = 'Downloadable',
}

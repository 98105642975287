import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'mp-help-center-direct-link-button',
  standalone: true,
  templateUrl: './help-center-direct-link-button.component.html',
  styleUrls: ['./help-center-direct-link-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MatButtonModule, MatIconModule, MatTooltipModule],
})
export class HelpCenterDirectLinkButtonComponent {
  @Input({ required: true }) helpCenterLink!: string;

  @Input({ required: true }) isSmall!: boolean;
}

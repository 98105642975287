import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HelpCenterSettings } from '@mp/shared/helper-links/help-center/domain';

@Injectable()
export class HelpCenterService {
  private readonly baseUrl = '/api/kernel/settings/deployment/helpcenter';

  constructor(private readonly http: HttpClient) {}

  /**
   * Fetches the help center settings from the backend.
   * @returns The help center settings.
   */
  getHelpCenterSettings(): Observable<HelpCenterSettings> {
    return this.http.get<HelpCenterSettings>(this.baseUrl);
  }

  /**
   * Updates the help center settings to the backend.
   * @param helpCenterSettings The settings to save.
   * @returns The saved settings.
   */
  updateHelpCenterSettings(helpCenterSettings: HelpCenterSettings): Observable<HelpCenterSettings> {
    return this.http.put<HelpCenterSettings>(this.baseUrl, { ...helpCenterSettings });
  }
}

<mat-card class="help-center-article">
  <div class="help-center-article__content">
    <span class="help-center-article__title">{{ articleTitle }}</span>
    <div class="help-center-article__text text-clamp">
      {{ articleHTMLDescription | stripHTML }}
    </div>
  </div>

  <a class="help-center-article__link" mat-button [href]="articleLink" target="_blank">
    <mat-icon class="material-icons-outlined">chevron_right</mat-icon>
    mehr
  </a>
</mat-card>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HelpCenterArticle } from '@mp/shared/zendesk/domain';

import { SupportTicketCreationData } from '../models';

import { ZendeskStore } from './zendesk.store';

@Injectable()
export class ZendeskFacade {
  readonly ticketCreationSuccess$: Observable<void> = this.store.ticketCreationSuccess$;

  readonly ticketCreationError$: Observable<void> = this.store.ticketCreationError$;

  readonly helpCenterArticles$: Observable<HelpCenterArticle[] | undefined> = this.store.helpCenterArticles$;
  readonly helpCenterArticlesCount$: Observable<number> = this.store.helpCenterArticlesCount$;

  constructor(private readonly store: ZendeskStore) {}

  createTicket(supportTicketCreationData: SupportTicketCreationData): void {
    this.store.createTicket(supportTicketCreationData);
  }

  searchArticles(labelName: string): void {
    this.store.searchArticles(labelName);
  }
}
